import api from "./api";

export default {
  async getProviders() {
    return await api.get(`/provider`);
  },

  async getProfile(providerNumber) {
    return await api.get(`/provider/${providerNumber}`);
  },

  async getSchools() {
    return await api.get("/schools");
  },

  async updateProfile(data) {
    return await api.put(`/provider/${data.providerId}`, data);
  },

  async suspend(provider) {
    return await api.post(`/provider/${provider.providerId}/suspend`, provider);
  },

  async activate(providerId) {
    return await api.post(`/provider/${providerId}/activate`);
  },

  async createProfile(data) {
    return await api.post("/provider", data);
  },

  async getOldUidProfile(uid) {
    return api.getNoAuth(
      `https://studentbenefits.ca/providerinformation.php?uid=${uid}`,
    );
  },
};
