import api from './api';

export default {
  async getProfile(memberId) {
    return await api.get(`/member?memberId=${memberId}`);
  },

  async findMembers(
    providerId = null,
    memberId = null,
    firstname = null,
    lastname = null,
    certificate = null,
    email = null
  ) {
    let paramsObj = {
      memberId,
      firstname,
      lastname,
      certificate,
      email,
    };
    // Remove empty query params
    Object.keys(paramsObj).forEach(
      (k) => (paramsObj[k] == '' || paramsObj[k] == null) && delete paramsObj[k]
    );
    let query = new URLSearchParams(paramsObj).toString();
    return await api.get(`/provider/${providerId}/member?${query}`);
  },

  async createMember(member) {
    return await api.post(`/member`, member);
  },

  async updateMember(update) {
    return await api.put(`/member/${update.memberId}`, update);
  },

  async deleteMember(id) {
    return await api.delete(`/member/${id}`);
  },
};
