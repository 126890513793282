<template>
  <v-container class="px-0 px-sm-4">
    <v-form ref="form" v-model="valid" @submit.prevent="(e) => searchMember(e)">
      <v-row v-if="offices.length > 1">
        <v-col cols="12" sm="11" class="pa-0 px-sm-2">
          <v-select
            v-model="officeName"
            :items="offices"
            item-text="officeName"
            :rules="[(v) => !!v || 'Please select Office']"
            label="Choose your Office"
            name="officeName"
            @input="$emit('officeSelected', officeName)"
            required
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <div class="text-caption mt-1">
          Enter Certificate number for a member you want to submit a claim for.
        </div>
      </v-row>
      <v-row>
        <v-col cols="12" md="10" class="pa-0 px-sm-2 mt-2">
          <v-text-field
            v-model="memberId"
            label="Certificate / Member ID"
            name="memberId"
            hint="Enter Certificate / Member ID to search"
            dense
            type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-btn
            tile
            :disabled="!memberId || provider.isSuspended"
            v-on:keyup.enter="search"
            color="primary"
            @click="search"
            :loading="loading1"
          >
            SEARCH
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <div class="text-caption mt-3">
          Search for member by first name or last name if you have already
          submitted claims.
        </div>
      </v-row>
      <v-row>
        <v-col cols="12" sm="5" class="pa-0 px-sm-2 mt-2">
          <v-text-field
            class="mt-2"
            v-model="firstname"
            label="First Name"
            name="firstname"
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="5" class="pa-0 px-sm-2 mt-2">
          <v-text-field
            class="mt-2"
            v-model="lastname"
            label="Last Name"
            name="lastname"
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-btn
            tile
            :disabled="
              !this.valid || isStatusNewProvider || provider.isSuspended
            "
            v-on:keyup.enter="search"
            color="primary"
            class="mr-4 mt-2"
            @click="search"
            :loading="loading2"
          >
            SEARCH
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-btn tile text class="mr-4 mt-2" @click="reset"> RESET </v-btn>
        <div class="my-auto">
          <small class="ml-4 mb-1 red--text" v-if="isStatusNewProvider"
            >You will not be able to search for members until your profile is
            approved
          </small>
        </div>
        <v-spacer></v-spacer>
      </v-row>
    </v-form>
    <MembersTable
      v-if="members.length"
      :members="members"
      class="mt-4"
      @memberSelected="memberSelected"
      @editMember="editMember"
    />

    <!-- Member Info -->
    <MemberDetail :member="selectedMember" v-if="showMemberDetail" />
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import MemberService from "../services/MemberService";
import MembersTable from "./MembersTable.vue";
import MemberDetail from "./MemberDetail.vue";
export default {
  components: { MembersTable, MemberDetail },
  props: ["member", "isStatusNewProvider"],
  data() {
    return {
      valid: false,
      loading1: false,
      loading2: false,
      openMemberForm: false,
      members: [],
      memberIdRule1: (v) => !!v || "Please enter ID",
      memberId: null,
      officeName: "",
      firstname: "",
      lastname: "",
      certificate: "",
      email: "",
      dob: "",
      plan: "",
      selectedMember: {},
      emptyMember: {
        address: "",
        certificate: "",
        city: "",
        comments: "",
        dependents: [],
        dob: "",
        email: "",
        firstname: "",
        lastname: "",
        memberId: "",
        phoneNumber: "",
        plan: "",
        subpolicy: "",
        postalCode: "",
        province: "",
      },
    };
  },
  created() {
    this.selectedMember = Object.assign(this.emptyMember);
    this.officeName = this.offices[0].officeName || "";
  },
  computed: {
    ...mapGetters("provider", ["offices"]),
    ...mapState("provider", ["provider"]),
    memberIdMatchRule() {
      return this.memberId == this.member?.memberId || "Memeber not found";
    },
    showMemberDetail() {
      return this.selectedMember.firstname !== "";
    },
    searchDisabled() {
      return (
        !this.valid ||
        (this.firstname === "" &&
          this.lastname === "" &&
          this.memberId === null &&
          this.email === "")
      );
    },
  },
  methods: {
    ...mapActions("notification", ["show"]),
    reset() {
      //this.$refs.form.reset();
      this.memberId = "";
      this.firstname = "";
      this.lastname = "";
      this.email = "";
      this.searchData = [];
      this.members = [];
      this.selectedMember = Object.assign(this.emptyMember);
      this.$emit("reset");
    },
    search() {
      this.memberId ? (this.loading1 = true) : (this.loading2 = true);
      MemberService.findMembers(
        this.provider.providerId,
        this.memberId ? this.memberId?.padStart(9, "0") : null,
        this.firstname,
        this.lastname,
        this.certificate,
        this.email
      )
        .then((res) => {
          this.reset();
          this.loading1 = false;
          this.loading2 = false;
          this.members = res.data;
          if (this.members.length === 0) {
            this.showNotification("error", `No Members found`);
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.showNotification("error", `${err}`);
        });
    },
    memberSelected(member) {
      this.selectedMember = member;
      this.$emit("memberSelected", member);
    },
    editMember() {
      this.openMemberForm = true;
    },
    addMember() {
      this.$emit("addNewMember", this.officeName);
      this.openMemberForm = true;
    },
    saveMember(member) {
      this.loading = true;
      if (member && member.memberId) {
        MemberService.updateMember(member)
          .then(() => {
            this.showNotification("success", `Updated Member's Profile`);
            this.openMemberForm = false;
            this.loading = false;
          })
          .catch((err) => {
            this.showNotification("error", `Failed to update member: ${err}`);
            this.loading = false;
          });
      } else {
        MemberService.createMember(member)
          .then(() => {
            this.showNotification("success", `Created new Member`);
            this.openMemberForm = false;
          })
          .catch((err) => {
            this.showNotification("error", `Failed to create member: ${err}`);
          });
      }
    },
    deleteMember(member) {
      this.loading = true;
      this.openMemberForm = false;
      MemberService.deleteMember(member.memberId)
        .then(() => {
          this.reset();
          this.showNotification(
            "success",
            `Deleted member: ${member.firstname} ${member.lastname}`
          );
        })
        .catch((err) => {
          this.showNotification("error", `Failed to delete member: ${err}`);
        });
    },
    closeDialog() {
      this.openMemberForm = false;
    },
    showNotification(type, message) {
      this.loading = false;
      const notification = { type, message };
      this.show(notification);
    },
  },
};
</script>


            