import api from "./api";

export default {
  async getCoverages() {
    return await api.get(`/coverages`);
  },

  async getPlanCoverages(plan) {
    return await api.get(`/plans/${plan}`);
  },

  async createCoverage(coverage) {
    return await api.post(`/coverages`, coverage);
  },

  async updateCoverage(id, update) {
    return await api.put(`/coverages/${id}`, update);
  },

  async deleteCoverage(id) {
    return await api.delete(`/coverages/${id}`);
  },
};
