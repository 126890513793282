var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Field")]),_c('th',[_vm._v("Old Data")]),_c('th',[_c('span',{staticClass:"red--text"},[_vm._v("New Data")])])])]),_c('tbody',_vm._l((Object.keys(
            _vm.approvalNewValue ? _vm.approvalNewValue : _vm.approvalOldValue
          )),function(row,i){return _c('tr',{key:i},[_c('td',[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.formatFieldNames(row))+":")])]),_c('td',[(row == 'offices')?_c('span',[_c('ApprovalOfficeDetail',{attrs:{"offices":_vm.approvalOldValue ? _vm.approvalOldValue[row] : {}}})],1):_vm._e(),(row == 'coverages')?_c('span',[_c('ApprovalCoverageDetail',{attrs:{"coverages":_vm.approvalOldValue ? _vm.approvalOldValue[row] : []}})],1):_vm._e(),(row != 'coverages' && row != 'offices')?_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.approvalOldValue ? _vm.approvalOldValue[row] : "---"))]):_vm._e()]),_c('td',{class:_vm.getClassIfUpdated(_vm.approvalOldValue, _vm.approvalNewValue, row) +
              '--text'},[(row == 'offices')?_c('span',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.getClassIfUpdated(
                    _vm.approvalOldValue,
                    _vm.approvalNewValue,
                    row
                  ) === 'red'
                ),expression:"\n                  getClassIfUpdated(\n                    approvalOldValue,\n                    approvalNewValue,\n                    row\n                  ) === 'red'\n                "}],staticClass:"ml-5"},[_vm._v("Offices information has changed")]),_c('ApprovalOfficeDetail',{attrs:{"offices":_vm.approvalNewValue[row],"showUpdate":_vm.getClassIfUpdated(_vm.approvalOldValue, _vm.approvalNewValue, row)}})],1):_vm._e(),(row == 'coverages')?_c('span',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.getClassIfUpdated(
                    _vm.approvalOldValue,
                    _vm.approvalNewValue,
                    row
                  ) === 'red'
                ),expression:"\n                  getClassIfUpdated(\n                    approvalOldValue,\n                    approvalNewValue,\n                    row\n                  ) === 'red'\n                "}],staticClass:"ml-5 mt-1"},[_vm._v("Coverages information has changed")]),_c('ApprovalCoverageDetail',{attrs:{"coverages":_vm.approvalNewValue[row],"showUpdate":_vm.getClassIfUpdated(_vm.approvalOldValue, _vm.approvalNewValue, row)}})],1):_vm._e(),(row != 'coverages' && row != 'offices')?_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.approvalNewValue ? _vm.approvalNewValue[row] : "---")+" ")]):_vm._e()])])}),0)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }