<template>
  <v-container>
    <v-card flat>
      <h1 class="mx-auto mb-3 text-h5 text-sm-h4">Edit Provider's Homepage</h1>
      <p>Edit provider's homepage content using editor below.</p>
    </v-card>

    <editor
      v-if="edit"
      :initialValue="editorText"
      :options="editorOptions"
      initialEditType="wysiwyg"
      previewStyle="vertical"
      @change="onEditorChange"
      ref="toastuiEditor"
    />

    <div style="margin-bottom: 20px">
      <div v-if="!edit" v-html="contentHTML"></div>

      <v-btn v-if="edit" @click="previewHtml" class="ma-2 ml-0">Preview</v-btn>
      <v-btn v-if="!edit" @click="editHtml" class="ma-2 ml-0">Edit</v-btn>
      <v-btn v-if="edit" @click="clearHtml" class="ma-2 ml-0" color="secondary"
        >Clear</v-btn
      >
      <v-btn
        @click="saveHtml"
        class="ma-2 ml-0"
        color="primary"
        :loading="loading"
        >Save</v-btn
      >
    </div>
  </v-container>
</template>
<script>
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/vue-editor";
import { mapActions } from "vuex";
import HomepageService from "../services/HompageService";

export default {
  name: "App",
  components: {
    editor: Editor,
  },
  data() {
    return {
      editorText: "This is initialValue.",
      contentHTML: "",
      loading: false,
      homepage: { html: "" },
      editorOptions: {
        language: "en-US",
        hideModeSwitch: true,
        usageStatistics: false,
      },
      edit: true,
    };
  },
  mounted() {
    this.loading = true;
    HomepageService.getHomepage()
      .then((res) => {
        if (res.data) {
          this.homepage = res.data;
        }
        this.editorText = this.homepage.html;
        this.$refs.toastuiEditor.invoke("setHTML", this.editorText, true);
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  },
  methods: {
    ...mapActions("notification", ["show"]),
    previewHtml() {
      let html = this.$refs.toastuiEditor.invoke("getHTML");
      this.contentHTML = html;
      this.edit = false;
    },
    onEditorChange() {
      this.editorText = this.$refs.toastuiEditor.invoke("setHtml");
    },

    saveHtml() {
      let html = "";
      if (this.edit) {
        html = this.$refs.toastuiEditor.invoke("getHTML");
      } else html = this.contentHTML;
      this.loading = true;
      this.homepage.html = html;
      HomepageService.updateHomepage(this.homepage, html)
        .then((res) => {
          this.showNotification("success", `Saved updated homepage`);
          this.homepage = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.showNotification("error", `Failed to save homepage: ${err}`);
          this.loading = false;
        });
    },
    editHtml() {
      this.edit = true;
      this.editorText = this.contentHTML;
    },
    clearHtml() {
      this.editorText = "";
      this.$refs.toastuiEditor.invoke("reset");
    },
    showNotification(type, message) {
      this.loading = false;
      const notification = { type, message };
      this.show(notification);
    },
  },
};
</script>
<style scoped>
</style>