<template>
  <v-card flat outlined>
    <v-card-text class="pa-1">
      <v-list-item>
        <v-list-item-content class="py-1">
          <div class="text-overline">
            {{ claim.submissionid }}
            <span class="float-right"
              >Date submitted:
              {{ formatDate(claim.submissionDate || date.toISOString()) }}</span
            >
          </div>

          <v-col class="pb-xs-2 pb-sm-0 pt-0">
            <span class="float-left text-body-2"
              >Provider Number:
              <strong>{{ claim.providerNumber }}</strong></span
            >
            <span class="float-left text-body-2 ml-sm-2"
              >Business ID: <strong>{{ claim.businessID }}</strong></span
            >
            <span class="float-right text-body-2"
              >Clinic \ Practitioner:
              <strong>{{ claim.businessName }}</strong></span
            >
          </v-col>
          <v-list-item-title class="text-h5 mb-1">
            <v-col class="pb-xs-2 pb-sm-0 pt-0">
              <v-chip class="mr-4">{{
                claim.planNumber === "777" ? "247" : claim.planNumber
              }}</v-chip
              >{{ claim.firstName }} {{ claim.lastName }}
              <span class="float-right text-body-2"
                >certificate / member ID: {{ claim.studentId }}</span
              >
            </v-col>
            <v-col class="pt-xs-2 pt-sm-0">
              <span class="text-subtitle-2 float-right"
                >Total:
                <strong>${{ claim.claimAmountTotal.padStart(5, "0") }}</strong>
              </span>
            </v-col>
            <v-row no-gutters>
              <v-col class="text-subtitle-2">
                Result of an accident:
                <strong>{{ claim.isAccident ? "Yes" : "No" }}</strong>
                <span v-if="claim.isAccident"> | {{ claim.accidentType }}</span>
              </v-col>
            </v-row>
            <div class="text-subtitle-2">Submitted Expenses</div>
            <v-card v-for="expense in claim.expense" :key="expense.expenseId">
              <v-card-text class="pa-1">
                date: <strong>{{ expense.expenseDate }}</strong> | patient:
                <strong>{{ expense.patientName }}</strong> | coverage:
                <strong>{{ expense.coverageType }}</strong> | amount:
                <strong>${{ expense.amount }}</strong> |
                <span class="float-right"
                  >referral:
                  <small v-if="expense.comments !== ''">{{
                    expense.comments
                  }}</small>
                  <span v-else>none</span>
                </span>
              </v-card-text>
            </v-card>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
  </v-card>
</template>

<script>
//import ReferralImageView from "../components/ReferralImageView.vue";
export default {
  //components: { ReferralImageView },
  props: ["claim"],
  data() {
    return {
      referralDialog: false,
      fileExtension: null,
      date: new Date(),
    };
  },
  methods: {
    formatDate(date) {
      const dateOnly = date?.split("T")[0];
      const timeArr = date?.split("T")[1].split(":");
      const formattedDateTime = `${dateOnly} | ${timeArr[0]}:${timeArr[1]}`;
      return formattedDateTime;
    },
    getImageUrl(expense) {
      this.fileExtension = expense.additionalInfoFile
        .split(".")[1]
        .toLowerCase();
      if (this.fileExtension === "pdf") {
        return `data:application/pdf;base64,${expense.base64}`;
      } else {
        return `data:image/${this.fileExtension};base64,${expense.base64}`;
      }
    },
  },
};
</script>

<style>
</style>