<template>
  <v-card flat>
    <v-card-text class="pt-0">
      <v-data-table
        dense
        :headers="headers"
        :items="searchData"
        :options.sync="tableOptions"
        :server-items-length="totalCount"
        :footer-props="{ 'items-per-page-options': [10, 15, 30, 50, 100, -1] }"
        show-expand
        :expanded="expanded"
        item-key="claimId"
        :item-class="getStatusClass"
      >
        <template v-slot:[`item.planNumber`]="{ item }">
          {{ item.planNumber === "777" ? "247" : item.planNumber }}
        </template>
        <template v-slot:[`item.submissionDate`]="{ item }">
          {{ formatDate(item.submissionDate) }}
        </template>
        <template v-slot:[`item.providerId`]="{ item }">
          {{ getProviderName(item.providerId) }}
        </template>
        <template v-slot:[`item.claimSubmissionLog`]="{ item }">
          <td class="px-0" :colspan="headers.length">
            <ClaimStatusSelect :item="item" />
          </td>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td class="px-0" :colspan="headers.length">
            <ClaimDetail :claim="item" />
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import ClaimDetail from "../components/ClaimDetail.vue";
import ClaimStatusSelect from "../components/ClaimStatusSelect.vue";
export default {
  components: { ClaimDetail, ClaimStatusSelect },
  props: ["searchData", "totalCount", "providers"],
  data() {
    return {
      expanded: [],
      tableLoading: false,
      tableOptions: {},
      headers: [
        {
          text: "SUBMISSION ID",
          align: "start",
          filterable: false,
          value: "submissionid",
          class: "blue--text",
        },
        { text: "PLAN", value: "planNumber", class: "blue--text" },
        {
          text: "PROVIDER",
          value: "providerId",
          class: "blue--text",
          align:
            this.$store.getters["auth/user"].profile.role === "Provider"
              ? " d-none"
              : "",
        },
        { text: "OFFICE", value: "healthOffice", class: "blue--text" },
        {
          text: "CERTIFICATE",
          value: "studentId",
          class: "blue--text",
        },
        { text: "LAST NAME", value: "lastName", class: "blue--text" },
        {
          text: "TOTAL",
          value: "claimAmountTotal",
          class: "blue--text",
        },
        { text: "DATE", value: "submissionDate", class: "blue--text" },
        {
          text: "STATUS",
          value: "claimSubmissionLog",
          class: "blue--text",
          width: "120",
        },
      ],
    };
  },

  methods: {
    formatDate(date) {
      return date.split("T")[0];
    },
    getProviderName(id) {
      const provider = this.providers.filter((p) => p.providerId === id)[0];
      if (provider && provider.providerName) {
        return provider.providerName;
      } else {
        return null;
      }
    },
    getStatus(log) {
      if (log && log.status) {
        switch (log.status) {
          case "A":
            return "Accepted";
          case "R":
            return "Rejected";
          case "E":
            return "Error";
          default:
            return "Pending";
        }
      }
    },
    getStatusClass(item) {
      if (item.claimSubmissionLog) {
        switch (item.claimSubmissionLog.status) {
          case "A":
            return "green lighten-5";
          case "R":
            return "yellow lighten-5";
          case "E":
            return "orange lighten-5";
          default:
            return "grey lighten-5";
        }
      }
    },
  },
  watch: {
    tableOptions: {
      handler() {
        this.$emit("pagination", this.tableOptions);
        this.expanded = [];
      },
      deep: true,
    },
    searchData: {
      handler() {
        this.expanded = [];
      },
      deep: true,
    },
  },
};
</script>
