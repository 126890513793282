export const namespaced = true;

export const state = {
  notification: '',
  show: false,
  closeBtn: -1,
};

export const mutations = {
  SHOW(state, notification) {
    state.notification = notification;
    state.show = true;
    state.closeBtn = notification.closeBtn ? -1 : 5000;
  },

  DELETE(state) {
    //state.notification = '';
    state.show = false;
  },
};

export const actions = {
  show({ commit }, notification) {
    commit('SHOW', notification);
  },
  remove({ commit }) {
    commit('DELETE');
  },
};

export const getters = {
  closeBtn: (state) => {
    return state.closeBtn;
  },
};
