import api from './api';

export default {
  async getApprovals(
    providerName = null,
    providerNumber = null,
    approvalStatus = null,
    timestamp = null,
    sort = 'timestamp',
    order = 'desc',
    limit = '10',
    page = '1'
  ) {
    let paramsObj = {
      providerName,
      providerNumber,
      approvalStatus,
      timestamp,
      sort,
      order,
      limit,
      page,
    };

    // Remove empty query params
    Object.keys(paramsObj).forEach(
      (k) => (paramsObj[k] == '' || paramsObj[k] == null) && delete paramsObj[k]
    );

    let query = new URLSearchParams(paramsObj).toString();

    return await api.get(`/audit?${query}`);
  },

  async getApproval(id) {
    return await api.get(`/audit/audit-record/${id}`);
  },

  async updateApproval(id, update) {
    return await api.put(`/audit/${id}`, update);
  },
};
