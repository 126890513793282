<template>
  <v-card :color="showUpdate" class="pa-1">
    <v-data-table
      :headers="headers"
      :items="coverages"
      class="elevation-1"
      dense
      disable-pagination
      hide-default-footer
      disable-sort
      item-class="text-caption"
    >
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: ["coverages", "showUpdate"],
  data() {
    return {
      headers: [
        { text: "Name", value: "name", class: "primary--text text-caption" },
        {
          text: "Assigned Id",
          value: "assignedId",
          class: "primary--text text-caption",
        },
      ],
    };
  },
  methods: {
    formatFieldNames(text) {
      const result = text.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;
    },
  },
};
</script>

<style>
</style>