<template>
  <v-navigation-drawer :mini-variant="mini" dark color="#007CB5" clipped app mobile-breakpoint="400" style="min-height: calc(100vh - 140px)">
    <v-list class="pa-0">
      <v-list-item v-for="link in links" :key="link.text" route :to="link.route" class="side-nav">
        <v-list-item-icon>
          <img :src="link.src" contain height="30" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ link.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Sidebar",
  props: ["links"],
  data() {
    return {};
  },
  computed: {
    mini() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>

<style scoped>
.side-nav {
  border-bottom: 0.5px solid white;
}
</style>
