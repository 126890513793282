<template>
  <!-- Member Info -->
  <div>
    <h2 class="text-subtitle-1">Selected Member</h2>
    <v-card class="pa-3 mt-2" color="grey lighten-3">
      <v-row no-gutterss>
        <v-col>
          <div>
            <v-chip class="mr-4">{{
              member.plan === "777" ? "247" : member.plan
            }}</v-chip
            >{{ member.firstname }} {{ member.lastname }}
            <span class="float-right text-body-2 ml-1">
              member ID / Certificate: <strong>{{ member.memberId }}</strong>
            </span>
            <span v-if="member.providerId" class="float-right text-body-2 ml-1">
              date of birth::
              <strong>{{ dateOfBirthFormatted(member.dob) }}</strong>
            </span>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["member"],
  methods: {
    dateOfBirthFormatted(dob) {
      return (
        dob.substring(0, 4) + "-" + dob.substring(4, 6) + "-" + dob.substring(6)
      );
    },
  },
};
</script>

<style>
</style>