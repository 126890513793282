<template>
  <v-row justify="center" no-gutters>
    <v-dialog v-model="edit" max-width="500px">
      <v-card class="pa-2 flex-grow-1">
        <v-card-title>{{
          office.pbasOfficeId ? "Edit Office" : "Add Office"
        }}</v-card-title>
        <v-card-text class="pt-0">
          <v-row class="mt-2">
            <v-col cols="12">
              <v-text-field
                dense
                hide-details
                v-model="office.name"
                label="Office Name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                dense
                hide-details
                v-model="office.address"
                label="Address"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                dense
                hide-details
                v-model="office.city"
                label="City"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                dense
                hide-details
                :items="provinces"
                v-model="office.province"
                label="Province"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                dense
                hide-details
                v-model="office.postalcode"
                label="Postal Code"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                dense
                hide-details
                v-model="office.telephone"
                label="Telephone"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                dense
                hide-details
                v-model="office.freephone"
                label="Toll Free"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                dense
                hide-details
                v-model="office.fax"
                label="Fax"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click.native="close">
            Close
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click.native="confirmDelete"
            v-show="office.pbasOfficeId"
          >
            Delete
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click.native="saveOffice"
            :disabled="saveDisabled"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Confirm Delete Dialog -->
    <v-dialog v-model="confirmDialog" max-width="450px">
      <v-card>
        <v-card-title> Are you sure? </v-card-title>
        <v-card-text>
          The
          <span class="primary--text font-weight-bold">{{ office.name }}</span>
          will be deleted!
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="confirmDialog = false">
            Cancel
          </v-btn>
          <v-btn color="error" text @click="deleteOffice"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
    
<script>
export default {
  props: ["edit", "office"],
  data() {
    return {
      confirmDialog: false,
      provinces: [
        "Ontario",
        "Alberta",
        "British Columbia",
        "Manitoba",
        "New Brunswick",
        "Newfoundland",
        "Northwest Territories",
        "Nova Scotia",
        "Nunavut",
        "Prince Edward Island",
        "Quebec",
        "Saskatchewan",
        "Yukon",
      ],
    };
  },
  computed: {
    saveDisabled() {
      return this.office.name === "";
    },
  },
  methods: {
    close() {
      this.$emit("close", false);
    },
    confirmDelete() {
      this.confirmDialog = true;
    },
    deleteOffice() {
      this.confirmDialog = false;
      this.$emit("delete", this.office);
    },
    saveOffice() {
      this.confirmDialog = false;
      this.$emit("save", this.office);
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin: 0 !important;
  padding: 0 !important;
}

.black--text /deep/ label {
  color: black;
}

.primary--text /deep/ label {
  color: #007cb5;
}
</style>