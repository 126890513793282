import Vue from "vue";
import Vuex from "vuex";
import api from "../services/api.js";
import * as provider from "@/store/modules/provider.js";
import * as enums from "@/store/modules/enums.js";
import * as auth from "@/store/modules/auth";
import * as notification from "@/store/modules/notification";
import * as member from "@/store/modules/member";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    provider,
    auth,
    notification,
    member,
    enums,
  },
  state: {
    claimSubmissions: [],
  },
  mutations: {
    SET_PROVIDER_PROFILE(state, profile) {
      state.providerProfile = profile;
    },
    SET_CLAIM(state, claim) {
      state.claim = claim;
    },
    SET_CLAIM_SUBMISSIONS(state, claimSubmissions) {
      state.claimSubmissions = claimSubmissions;
    },
  },
  actions: {
    getProvider(context) {
      context.commit("SET_LOADING_STATUS", "loading");
      api.get("/provider").then((response) => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.commit("SET_PROVIDER_PROFILE", response.data.provder);
      });
    },
  },
  getters: {
    // example of getter
    completedSubmissions(state) {
      return state.claimSubmissions.filter(
        (claim) => claim.status === "completed",
      );
    },
  },
});
