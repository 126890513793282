<template>
  <v-container class="pt-0">
    <v-card class="mx-auto" flat>
      <h1 class="py-0 mb-3 text-h5 mt-3">Admin Search Submissions</h1>
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-text-field
                v-model="submission_id"
                label="Submission ID"
                name="submission_id"
                v-on:keyup.enter="search"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-autocomplete
                v-model="providerId"
                :items="providers"
                v-on:keyup.enter="search"
                item-text="providerName"
                item-value="providerId"
                label="Provider Name"
                name="providerName"
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-autocomplete
                v-model="officeName"
                :items="offices"
                v-on:keyup.enter="search"
                item-text="officeName"
                label="Office Name"
                name="officeName"
                dense
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-text-field
                v-model="memberId"
                v-on:keyup.enter="search"
                label="Certificate / Member ID"
                type="number"
                name="memberId"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-text-field
                v-model="firstname"
                v-on:keyup.enter="search"
                label="First name"
                name="firstname"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-text-field
                v-model="lastname"
                v-on:keyup.enter="search"
                label="Last name"
                name="lastname"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="2" class="pt-0">
              <v-autocomplete
                v-model="plan"
                :items="plans"
                v-on:keyup.enter="search"
                label="Plan"
                name="plan"
                dense
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="2" class="pt-0">
              <v-autocomplete
                v-model="status"
                v-on:keyup.enter="search"
                :items="statuses"
                item-text="status"
                item-value="value"
                label="Status"
                name="status"
                dense
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="4" class="pt-0">
              <v-select
                v-model="coverages"
                v-on:keyup.enter="search"
                :items="coveragesList"
                multiple
                label="Coverages"
                name="coverages"
                dense
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="4" class="pt-0">
              <v-menu
                v-model="showDatepicker"
                :nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                left
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    label="Date or range"
                    name="submissionDate"
                    append-icon="mdi-calendar"
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker no-title v-model="dates" range></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-btn
            tile
            :disabled="!valid"
            v-on:keyup.enter="search"
            color="primary"
            class="mr-4"
            @click="search"
            :loading="loading"
          >
            SEARCH
          </v-btn>
          <v-btn tile text class="mr-4" @click="reset"> RESET </v-btn>
        </v-container>
      </v-form>
    </v-card>
    <p v-if="noSearchData" class="red--grey text-center">
      No Submissions found for current search
    </p>
    <SubmissionsTable
      v-if="searchData.length"
      :searchData="searchData"
      :totalCount="totalCount"
      :providers="providers"
      @pagination="search"
    />
  </v-container>
</template>
<script>
import SubmissionsTable from "../components/SubmissionsTable.vue";
import ClaimService from "../services/ClaimService";
import PlansService from "../services/PlansService";
import CoveragesService from "../services/CoveragesService";
import ProviderService from "../services/ProviderService";
import OfficesService from "../services/OfficesService";
export default {
  components: { SubmissionsTable },
  data() {
    return {
      showDatepicker: false,
      valid: false,
      loading: false,
      dates: [],
      submission_id: "",
      memberId: "",
      providerNumber: "",
      providerId: null,
      providers: [],
      offices: [],
      officeName: "",
      firstname: "",
      lastname: "",
      plan: "",
      sortBy: "",
      orderBy: "",
      limit: "10",
      page: "1",
      status: "",
      searchData: [],
      totalCount: 0,
      noSearchData: false,
      plans: [],
      coverages: [],
      allCoverages: [],
      nameRules: [(v) => !!v || "Name is required"],
      statuses: [
        { status: "Accepted", value: "A" },
        { status: "Error", value: "E" },
        { status: "Pending", value: "P" },
        { status: "Rejected", value: "R" },
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
      },
    };
  },
  computed: {
    coveragesList() {
      return this.allCoverages.map((c) => c.name);
    },
    dateRangeText: {
      get() {
        return this.dates.join(" ~ ");
      },
      set(dates) {
        return dates;
      },
    },
  },
  async created() {
    const res = await PlansService.getPlans();
    if (res && res.data) {
      this.plans = res.data.map((plan) => plan.planNumber.split("-")[0]);
      const tempPlans = res.data.map((plan) => plan.planNumber.split("-")[0]);
      this.plans = tempPlans
        .filter(function (value, index, array) {
          return array.indexOf(value) === index;
        })
        .sort();
    }
    CoveragesService.getCoverages().then((res) => {
      this.allCoverages = res.data;
    });
    ProviderService.getProviders().then((res) => {
      this.providers = res.data;
    });
    OfficesService.getOffices().then((res) => {
      this.offices = res.data;
    });
    ClaimService.updateClaimStatuses().then(() => {
      console.log("sent request to api to update claim statuses");
    });
  },
  methods: {
    reset() {
      this.$refs.form.reset();
      this.searchData = [];
      this.noSearchData = false;
      this.dates = [];
    },
    search(options) {
      if (options && options.itemsPerPage) {
        this.options = options;
      }
      this.loading = true;
      let sortOrder = "";
      this.noSearchData = false;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      if (sortBy?.length === 1 && sortDesc?.length === 1) {
        sortOrder = sortDesc[0] ? "asc" : "desc";
      }

      // claims for members of 247 plan are saved as plan 777 in db so replacing 247 here
      if (this.plan === "247") this.plan = "777";

      const sortColumn = sortBy ? sortBy[0] : "submissionDate";
      ClaimService.find(
        this.submission_id,
        this.memberId,
        this.providerId,
        this.officeName,
        this.firstname,
        this.lastname,
        this.plan,
        this.coverages,
        this.dates,
        sortColumn,
        sortOrder || this.orderBy,
        itemsPerPage || 10,
        page || 1,
        this.status
      ).then((res) => {
        this.totalCount = parseInt(res.headers["x-total-count"]);
        this.searchData = res.data;
        if (!this.searchData.length) this.noSearchData = true;
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped>
p {
  text-align: justify;
}
</style>