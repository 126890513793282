<template>
  <v-container>
    <h1 class="py-0 mb-2 text-h5 font-weight-bold">Accessibility Feedback</h1>
    <v-row>
      <v-col>
        <v-img
          :src="require('@/assets/pbas_group_logo.svg')"
          width="400"
          contain
          class="float-left"
        />
      </v-col>
    </v-row>
    <v-col class="mt-2">
      <p>
        To provide feedback regarding the way we provide products and services
        to people with disabilities, please contact us:
      </p>
      <p><strong>Telephone: </strong> 416-674-3350</p>
      <p><strong>Fax: </strong> 416-674-8018</p>
      <p>
        <strong>E-mail: </strong
        ><a href="mailto:accessibility@pbas.ca" class="text-decoration-none"
          >accessibility@pbas.ca</a
        >
      </p>

      <p><strong>Mail:</strong></p>

      <p>
        PBAS<br />61 International Blvd., Suite 110<br />Toronto, Ontario M9W
        6K4
      </p>

      <p>
        Please contact us to request an alternate format or communication
        support.
      </p>
    </v-col>
    <v-col>
      <v-btn tile color="secondary" href="#" class="mr-4"
        >ACCESSIBLE CUSTOMER SERVICE</v-btn
      >
      <v-btn tile color="secondary" href="#"
        >MULTI-YEAR ACCESSIBILITY PLAN</v-btn
      >
    </v-col>
  </v-container>
</template>
<script>
export default {};
</script>
<style scoped>
p {
  text-align: justify;
}
</style>