var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"px-lg-10",attrs:{"flat":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"loading":_vm.loading,"dense":"","headers":_vm.headers,"items":_vm.searchData,"options":_vm.options,"server-items-length":_vm.totalCount,"footer-props":{ 'items-per-page-options': [10, 15, 30, 50, 100, -1] },"show-expand":"","item-key":"id","single-expand":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.timestamp))+" ")]}},{key:"item.approvalStatus",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.approvalStatus === 'Approved'
              ? 'green--text'
              : item.approvalStatus === 'Rejected'
              ? 'red--text'
              : 'black--text'},[_vm._v(_vm._s(item.approvalStatus))])]}},{key:"item.newValue.providerId",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.newValue ? item.newValue.providerId : item.oldValue.providerId)+" ")]}},{key:"item.newValue.providerNumber",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.newValue ? item.newValue.providerNumber : item.oldValue.providerNumber)+" ")]}},{key:"item.approvals",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-1",attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.updateApproval(item, 'Approved')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-check ")])],1)]}}],null,true)},[_c('span',[_vm._v("approve")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-1",attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.updateApproval(item, 'Rejected')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v("reject")])])],1)]}},{key:"expanded-item",fn:function(ref){
              var headers = ref.headers;
              var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":headers.length}},[_c('ApprovalDetail',{attrs:{"approval":item}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }