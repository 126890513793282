import ProviderService from "../../services/ProviderService";

export const namespaced = true;

export const state = {
  provider: {
    providerId: null,
    registrationNumber: null,
    providerNumber: null,
    providerName: null,
    officeId: null,
    phoneNumber: null,
    phoneExtension: null,
    address: null,
    city: null,
    province: null,
    postalCode: null,
    offices: [],
    coverages: [],
    primaryContactFirstname: null,
    primaryContactLastname: null,
    institutionNumber: null,
    transitNumber: null,
    accountType: null,
    accountNumber: null,
    verifyDirectDeposit: false,
    verifyProviderRegistration: false,
    directDepositEmail: null,
    username: null,
    email: null,
    password: null,
    newPassword: null,
    confirmPassword: null,
    file: null,
    securityQuestion: null,
    securityAnswer: null,
    timezone: null,
    newValue: null,
    oldValue: null,
  },
};

export const mutations = {
  SET_PROVIDER_PROFILE(state, profile) {
    state.provider = profile;
  },
  SET_PROVINCES(state, provinces) {
    state.provinces = provinces;
  },
};

export const actions = {
  setProfile({ commit }, profile) {
    commit("SET_PROVIDER_PROFILE", profile);
  },
  getProfile({ commit }, providerNumber) {
    try {
      ProviderService.getProfile(providerNumber).then(({ data }) => {
        commit("SET_PROVIDER_PROFILE", data);
      });
    } catch (err) {
      console.log(err);
    }
  },
  updateProfile({ dispatch }) {
    ProviderService.updateProfile(state.provider)
      .then(() => {
        const notification = {
          type: "success",
          message: "Profile saved",
        };
        dispatch("notification/show", notification, { root: true });
      })
      .catch((err) => {
        const notification = {
          type: "error",
          message: err.message,
        };
        dispatch("notification/show", notification, { root: true });
      });
  },
};

export const getters = {
  offices(state) {
    return state.provider.offices;
  },
};
