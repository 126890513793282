<template>
  <v-select
    flat
    v-model="item.isSuspended"
    :items="statuses"
    item-value="value"
    @click.stop="loading = true"
    @change="setStatus(item)"
    :background-color="item.isSuspended ? 'orange lighten-5' : ''"
    :loading="saving"
    hide-details
    dense
    solo
  >
    <template #selection="">
      <v-chip
        small
        :class="
          item.isSuspended
            ? 'error lighten-3 caption'
            : 'success lighten-3 caption'
        "
        >{{ item.isSuspended ? "Suspended" : "Approved" }}
      </v-chip>
    </template>
  </v-select>
</template>

<script>
export default {
  props: ["item", "saving"],
  data() {
    return {
      icon: "mdi-some",
      loading: false,
      statuses: [
        { text: "Suspended", value: true },
        { text: "Approved", value: false },
      ],
    };
  },
  methods: {
    setStatus(item) {
      this.$emit("set-status", item);
    },
  },
};
</script>

<style scoped>
.v-input__slot {
  padding: 0 !important;
}
</style>