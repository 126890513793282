<template>
  <v-footer class="secondary lighten-2" dark style="z-index: 5">
    <div>
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="admin"
              class="my-1"
              fab
              dark
              x-small
              color="secondary"
              v-bind="attrs"
              v-on="on"
              @click="editFooter = !editFooter"
            >
              <v-icon dark> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <span>edit offices</span>
        </v-tooltip>
      </div>
      <div v-show="editFooter">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="my-1"
              fab
              dark
              x-small
              color="secondary"
              v-bind="attrs"
              v-on="on"
              @click="addOffice"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>add office</span>
        </v-tooltip>
      </div>
    </div>
    <v-container>
      <v-row justify="center">
        <v-tabs
          align-with-title
          class="d-flex justify-center"
          background-color="secondary lighten-2"
          hide-slider
          show-arrows=""
        >
          <v-tab
            v-for="link in links"
            :key="link.text"
            route
            :to="link.route"
            >{{ link.text }}</v-tab
          >
        </v-tabs>
      </v-row>
      <v-row justify="space-around" no-gutters>
        <v-col
          class="py-0 px-1"
          cols="12"
          sm="4"
          md="3"
          v-for="office in offices"
          :key="office.pbasOfficeId || office.name"
        >
          <h2 class="text-h6 mt-2 mb-1">{{ office.name }}</h2>
          <p class="text-body-2 my-0">{{ office.address }}</p>
          <p class="text-body-2 my-0">
            {{ office.city }}, {{ office.province }}, {{ office.postalcode }}
          </p>
          <p class="text-body-2 my-0">Tel: {{ office.telephone }}</p>
          <p class="text-body-2 my-0">Free: {{ office.freephone }}</p>
          <p class="text-body-2 my-0">Fax: {{ office.fax }}</p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<script>
import PbasOfficesService from "../services/PbasOfficesService";
import { mapGetters } from "vuex";
export default {
  data: () => ({
    editFooter: false,
    links: [
      {
        text: "FAQ",
        route: "/faq",
      },
      {
        text: "Privacy Policy",
        route: "/privacy",
      },
      {
        text: "Accessibility",
        route: "/accessibility",
      },
    ],
    offices: [
      {
        name: "Eastern Office:",
        address: `101, 20 Crosbie Place
Beothuck Building
St. John's, Newfoundland A1B 3Y8
Tel: (709) 754-6633
Free: (800) 563-1930
Fax: (709) 754-6733`,
      },
      {
        name: "Central Office:",
        address: `Suite 203, 61 International Blvd,
Toronto, Ontario M9W 6K4
Tel: (416) 674-2600
Free: (888) 404-6623
Fax: (416) 674-9968`,
      },
      {
        name: "Western Office:",
        address: `318B-2099 Lougheed Highway
Port Coquitlam, 
British Columbia V3B 1A8
Tel: (604) 945-7607
Free: (833) 674-2600
Fax: (604) 745-7657`,
      },
    ],
  }),
  computed: {
    ...mapGetters("auth", ["user"]),
    admin() {
      return this.user?.profile.role === "Administrator";
    },
  },
  mounted() {
    this.getPbasOffices();
  },
  methods: {
    addOffice() {
      this.offices.push({
        name: "New Office",
        address: "",
        city: "",
        province: "",
        postalcode: "",
        telephone: "",
        freephone: "",
        fax: "",
      });
    },
    getPbasOffices() {
      this.editFooter = false;
      PbasOfficesService.getPbasOffices()
        .then((res) => {
          this.offices = res.data;
        })
        .catch((err) => {
          console.log("Unable to get PBAS offices info: ", err);
        });
    },
    doNotSaveNew() {
      this.editFooter = false;
      this.offices.pop();
    },
  },
};
</script>
<style scoped>
</style>