<template>
  <v-container>
    <v-row>
      <h1 class="py-0 mb-3 mx-2 text-h5 mt-3">
        Add / Remove / Update coverage plans
      </h1>
      <v-btn
        tile
        color="secondary"
        class="ma-2 ml-sm-auto"
        type="submit"
        @click="newPlanDialog = true"
        :loading="loading"
        >CREATE NEW PLAN</v-btn
      >
    </v-row>
    <v-item-group>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          lg="4"
          v-for="plan in plans"
          :key="plan.planId"
          class="d-flex"
          style="flex-direction: column"
        >
          <v-item>
            <Plan :plan="plan" @edit="editPlan" :loading="loading" />
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>
    <EditPlanForm
      :dialog="dialog"
      :plan="planToEdit"
      :isNew="false"
      @close="close"
      @delete="deletePlan"
      @save="savePlan"
    />

    <EditPlanForm
      :dialog="newPlanDialog"
      :plan="newPlan"
      :isNew="true"
      @close="close"
      @save="createPlan"
    />
  </v-container>
</template>

<script>
import PlansService from "../services/PlansService";
import Plan from "../components/Plan.vue";
import EditPlanForm from "../components/EditPlanForm.vue";
import { mapActions } from "vuex";
export default {
  components: { Plan, EditPlanForm },
  data() {
    return {
      plans: [],
      dialog: false,
      newPlanDialog: false,
      planToEdit: {},
      loading: false,
      valid: false,
      newPlan: {
        planNumber: "",
        planName: "",
        effectiveDate: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        coverages: [],
      },
    };
  },
  created() {
    this.loading = true;
    this.loadPlans();
  },
  methods: {
    ...mapActions("notification", ["show"]),
    editPlan(plan) {
      this.planToEdit = plan;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.newPlanDialog = false;
      this.loadPlans();
    },
    deletePlan(plan) {
      this.loading = true;
      this.dialog = false;
      PlansService.deletePlan(plan.planId)
        .then(() => {
          this.showNotification("success", `Deleted plan: ${plan.planNumber}`);
          this.loadPlans();
        })
        .catch((err) => {
          this.showNotification("error", `Failed to delete plan: ${err}`);
        });
    },
    createPlan(plan) {
      this.loading = true;
      this.newPlanDialog = false;
      PlansService.createPlan(plan)
        .then(() => {
          this.newPlan = {
            planNumber: "",
            planName: "",
            effectiveDate: new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .substr(0, 10),
            coverages: [],
          };
          this.showNotification("success", "New plan created");
          this.loadPlans();
        })
        .catch((err) => {
          this.showNotification("error", `Failed to create plan: ${err}`);
        });
    },

    savePlan(plan) {
      this.loading = true;
      this.dialog = false;
      PlansService.updatePlan(plan.planId, plan)
        .then(() => {
          this.showNotification("success", `Updated plan: ${plan.planNumber}`);
          this.loadPlans();
        })
        .catch((err) => {
          this.showNotification("error", `Failed to update plan: ${err}`);
          this.loadPlans();
        });
    },

    showNotification(type, message) {
      this.loading = false;
      const notification = { type, message };
      this.show(notification);
    },
    loadPlans() {
      PlansService.getPlans()
        .then((res) => {
          this.plans = res.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>