<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ma-1"
          fab
          dark
          x-small
          color="secondary"
          v-show="editFooter"
          v-bind="attrs"
          v-on="on"
          @click="editOffice"
          :loading="loading"
        >
          <v-icon dark> mdi-playlist-edit </v-icon>
        </v-btn>
      </template>
      <span>edit</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ma-1"
          fab
          dark
          x-small
          color="secondary"
          v-show="editFooter"
          v-bind="attrs"
          v-on="on"
          @click="confirmDialog = true"
          :loading="loading"
        >
          <v-icon dark> mdi-minus </v-icon>
        </v-btn>
      </template>
      <span>remove</span>
    </v-tooltip>
    <h2 class="text-h6 mt-2 mb-1">{{ office.name }}</h2>
    <p class="text-body-2 my-0">{{ office.address }}</p>
    <p class="text-body-2 my-0">
      {{ office.city }}, {{ office.province }}, {{ office.postalcode }}
    </p>
    <p class="text-body-2 my-0">Tel: {{ office.telephone }}</p>
    <p class="text-body-2 my-0">Free: {{ office.freephone }}</p>
    <p class="text-body-2 my-0">Fax: {{ office.fax }}</p>
    <EditOfficeForm
      :edit="editOfficeMode"
      :office="office"
      @close="editOfficeMode = false"
      @delete="deleteOffice"
      @save="saveOffice"
    />

    <!-- Confirm Delete Dialog -->
    <v-dialog v-model="confirmDialog" max-width="450px">
      <v-card>
        <v-card-title> Are you sure? </v-card-title>
        <v-card-text>
          The
          <span class="primary--text font-weight-bold">{{ office.name }}</span>
          will be deleted!
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="confirmDialog = false">
            Cancel
          </v-btn>
          <v-btn color="error" text @click="deleteOffice"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PbasOfficesService from "../services/PbasOfficesService";
import { mapActions } from "vuex";
import EditOfficeForm from "../components/EditOfficeForm.vue";
export default {
  props: ["office", "editFooter"],
  components: { EditOfficeForm },
  data() {
    return {
      loading: false,
      confirmDialog: false,
      editOfficeMode: false,
    };
  },
  methods: {
    ...mapActions("notification", ["show"]),
    saveOffice() {
      this.loading = true;
      if (!this.office.pbasOfficeId) {
        this.createOffice();
        this.$emit("saved");
        this.editOfficeMode = false;
        this.loading = false;
        return;
      }
      PbasOfficesService.updatePbasOffice(this.office.pbasOfficeId, this.office)
        .then(() => {
          this.showNotification("success", `Saved ${this.office.name}`);
          this.editOfficeMode = false;
          this.loading = false;
          this.$emit("saved");
        })
        .catch((err) => {
          this.showNotification(
            "error",
            `Failed to save Office ${this.office.name}: ${err}`
          );
          this.loading = false;
        });
    },

    createOffice() {
      this.loading = true;
      PbasOfficesService.createPbasOffice(this.office)
        .then(() => {
          this.showNotification("success", `Added ${this.office.name}`);
          this.loading = false;
          this.$emit("saved");
        })
        .catch((err) => {
          this.showNotification(
            "error",
            `Failed to add Office ${this.office.name}: ${err}`
          );
          this.loading = false;
        });
    },
    editOffice() {
      this.editOfficeMode = true;
    },

    deleteOffice() {
      this.loading = true;
      if (!this.office.pbasOfficeId) {
        this.$emit("donotsave");
        this.loading = false;
        return;
      }
      PbasOfficesService.deletePbasOffice(this.office.pbasOfficeId)
        .then(() => {
          this.showNotification("success", `deleted ${this.office.name}`);
          this.loading = false;
          this.$emit("saved");
        })
        .catch((err) => {
          this.showNotification(
            "error",
            `Failed to delete ${this.office.name}: ${err}`
          );
          this.loading = false;
        });
    },
    showNotification(type, message) {
      this.loading = false;
      const notification = { type, message };
      this.show(notification);
    },
  },
};
</script>

<style scoped>
.address >>> .v-input__slot::before {
  border-style: none !important;
}
</style>