<template>
  <v-card
    max-width="300"
    class="pa-2 flex-grow-1"
    hover
    link
    @click="edit"
    :loading="loading"
  >
    <v-card-text class="pa-0 black--text text-subtitle-1">
      {{ coverage.name }}
      <div class="float-right">
        <v-avatar color="grey lighten-2" size="36">{{
          coverage.assignedId
        }}</v-avatar>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["coverage", "loading"],
  methods: {
    edit() {
      this.$emit("edit", this.coverage);
    },
  },
};
</script>

<style>
</style>