import api from './api';

export default {
  async submitClaim(data) {
    return await api.post('/claims', data);
  },

  async find(
    submissionId = null,
    memberId = null,
    providerId = null,
    officeName = null,
    firstname = null,
    lastname = null,
    planNumber = null,
    coverages,
    dates,
    sort = 'submissionDate',
    order = 'asc',
    limit = '10',
    page = '1',
    status
  ) {
    let paramsObj = {
      submissionId,
      memberId,
      providerId,
      officeName,
      firstname,
      lastname,
      planNumber,
      coverages,
      dates,
      sort,
      order,
      limit,
      page,
      status,
    };

    // Remove empty query params
    Object.keys(paramsObj).forEach(
      (k) => (paramsObj[k] == '' || paramsObj[k] == null) && delete paramsObj[k]
    );

    let query = new URLSearchParams(paramsObj).toString();
    return await api.get(`/claims?${query}`);
  },

  async setClaimStatus(id, claim) {
    return await api.put(`/claims/${id}/setStatus`, claim);
  },

  async updateClaimStatuses() {
    return await api.get(`/claims/UpdateStatus`);
  },
};
