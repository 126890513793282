<template>
  <div>
    <v-card
      v-for="(office, index) in offices || []"
      :key="index"
      class="ma-1"
      :color="showUpdate"
    >
      <v-card-text class="pa-1">
        <v-simple-table dense>
          <template v-slot:default>
            <tbody>
              <tr v-for="cell in Object.keys(office)" :key="cell">
                <td>
                  <span class="primary--text text-caption"
                    >{{ formatFieldNames(cell) }}:</span
                  >
                </td>
                <td>
                  <span class="text-caption">{{ office[cell] }}</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["offices", "showUpdate"],
  methods: {
    formatFieldNames(text) {
      const result = text.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;
    },
  },
};
</script>
