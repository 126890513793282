import api from './api';

export default {
  async getPlans() {
    return await api.get(`/plans`);
  },

  async createPlan(plan) {
    return await api.post(`/plans`, plan);
  },

  async updatePlan(id, update) {
    return await api.put(`/plans/${id}`, update);
  },

  async deletePlan(id) {
    return await api.delete(`/plans/${id}`);
  },
};
