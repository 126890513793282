<template>
  <v-container>
    <v-card flat>
      <h1 class="mx-auto mb-3 text-h5 text-sm-h4">Approval Requests</h1>

      <p>View / Approve requests for change</p>
    </v-card>
    <v-form ref="form" v-model="valid">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="providerName"
              label="Name"
              name="providerName"
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="providerNumber"
              label="Provider Number"
              name="providerNumber"
              hide-details
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              v-model="approvalStatus"
              v-on:keyup.enter="search"
              :items="statusOptions"
              label="Approval Status"
              name="approvalStatus"
              dense
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6">
            <v-menu
              v-model="showDatepicker"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="timestamp"
                  label="Request Date"
                  name="timestamp"
                  prepend-icon="mdi-calendar"
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="timestamp"
                @input="showDatepicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-btn
          tile
          :disabled="!valid"
          v-on:keyup.enter="search"
          color="primary"
          class="mr-4"
          @click="search"
          :loading="loading"
        >
          SEARCH
        </v-btn>
        <v-btn tile text class="mr-4" @click="reset"> RESET </v-btn>
      </v-container>
    </v-form>
    <ApprovalsTable
      :searchData="searchData"
      :totalCount="totalCount"
      @pagination="search"
      :loading="loading"
    />
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import ApprovalsService from "../services/ApprovalsService";
import ApprovalsTable from "../components/ApprovalsTable.vue";
export default {
  components: { ApprovalsTable },
  data() {
    return {
      showDatepicker: false,
      valid: false,
      loading: false,
      providerName: "",
      providerNumber: "",
      approvalStatus: "",
      timestamp: "",
      approvals: [],
      searchData: [],
      totalCount: 100,
      sortBy: "",
      orderBy: "",
      limit: "10",
      page: "1",
      statusOptions: ["", "Approved", "Pending", "Rejected"],
    };
  },
  mounted() {
    this.loadApprovalRequests();
  },
  methods: {
    ...mapActions("notification", ["show"]),
    showNotification(type, message) {
      this.loading = false;
      const notification = { type, message };
      this.show(notification);
    },
    reset() {
      this.$refs.form.reset();
      this.loadApprovalRequests();
    },
    search(options) {
      this.loading = true;
      let sortOrder = "";
      const { sortBy, sortDesc, page, itemsPerPage } = options;
      if (sortBy?.length === 1 && sortDesc?.length === 1) {
        sortOrder = sortDesc[0] ? "asc" : "desc";
      }
      ApprovalsService.getApprovals(
        this.providerName,
        this.providerNumber,
        this.approvalStatus,
        this.timestamp,
        sortBy || this.sortBy,
        sortOrder || this.orderBy,
        itemsPerPage || 10,
        page || 1
      )
        .then((res) => {
          this.searchData = res.data;
          this.totalCount = parseInt(res.headers["x-total-count"]);
          this.loading = false;
        })
        .catch((err) => {
          this.showNotification(
            "error",
            `Failed to load approvals list: ${err}`
          );
          this.loading = false;
        });
    },
    loadApprovalRequests() {
      ApprovalsService.getApprovals()
        .then((res) => {
          this.searchData = res.data;
          this.totalCount = parseInt(res.headers["x-total-count"]);
        })
        .catch((err) => {
          this.showNotification(
            "error",
            `Failed to load approvals list: ${err}`
          );
        });
    },
  },
};
</script>
<style scoped>
p {
  text-align: justify;
}
</style>