<template>
  <v-footer class="secondary lighten-2" dark style="z-index: 5">
    <div>
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="admin"
              class="my-1"
              fab
              dark
              x-small
              color="secondary"
              v-bind="attrs"
              v-on="on"
              @click="editFooter = !editFooter"
            >
              <v-icon dark
                >{{ !editFooter ? "mdi-pencil" : "mdi-check" }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ !editFooter ? "edit offices" : "save offices" }}</span>
        </v-tooltip>
      </div>
      <div v-show="editFooter">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="my-1"
              fab
              dark
              x-small
              color="secondary"
              v-bind="attrs"
              v-on="on"
              @click="addNewOffice = true"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>add office</span>
        </v-tooltip>
      </div>
    </div>
    <v-container>
      <v-row justify="center">
        <v-tabs
          align-with-title
          class="d-flex justify-center"
          background-color="secondary lighten-2"
          hide-slider
          show-arrows=""
        >
          <v-tab
            v-for="link in links"
            :key="link.text"
            route
            :to="link.route"
            >{{ link.text }}</v-tab
          >
        </v-tabs>
      </v-row>
      <v-row justify="space-around" no-gutters>
        <v-col
          class="py-0 px-1"
          cols="12"
          sm="4"
          md="3"
          v-for="office in offices"
          :key="office.pbasOfficeId || office.name"
        >
          <PbasOffice
            :office="office"
            :editFooter="editFooter"
            @saved="getPbasOffices"
            @donotsave="doNotSaveNew"
          />
        </v-col>
      </v-row>
      <EditOfficeForm
        :edit="addNewOffice"
        :office="newOffice"
        @close="addNewOffice = false"
        @save="addOffice"
      />
    </v-container>
  </v-footer>
</template>
<script>
import PbasOfficesService from "../services/PbasOfficesService";
import EditOfficeForm from "../components/EditOfficeForm.vue";
import PbasOffice from "../components/PbasOffice.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { PbasOffice, EditOfficeForm },
  data: () => ({
    editFooter: false,
    editOffice: false,
    addNewOffice: false,
    newOffice: {
      name: "",
      address: "",
      city: "",
      province: "",
      postalcode: "",
      telephone: "",
      freephone: "",
      fax: "",
    },
    emptyOffice: {
      name: "",
      address: "",
      city: "",
      province: "",
      postalcode: "",
      telephone: "",
      freephone: "",
      fax: "",
    },
    links: [
      {
        text: "FAQ",
        route: "/faq",
      },
      {
        text: "Privacy Policy",
        route: "/privacy",
      },
      {
        text: "Accessibility",
        route: "/accessibility",
      },
    ],
    offices: [
      {
        name: "Eastern Office:",
        address: `101, 20 Crosbie Place
Beothuck Building
St. John's, Newfoundland A1B 3Y8
Tel: (709) 754-6633
Free: (800) 563-1930
Fax: (709) 754-6733`,
      },
      {
        name: "Central Office:",
        address: `Suite 203, 61 International Blvd,
Toronto, Ontario M9W 6K4
Tel: (416) 674-2600
Free: (888) 404-6623
Fax: (416) 674-9968`,
      },
      {
        name: "Western Office:",
        address: `318B-2099 Lougheed Highway
Port Coquitlam, 
British Columbia V3B 1A8
Tel: (604) 945-7607
Free: (833) 674-2600
Fax: (604) 745-7657`,
      },
    ],
  }),
  computed: {
    ...mapGetters("auth", ["user"]),
    admin() {
      return this.user?.profile.role === "Administrator";
    },
  },

  mounted() {
    this.getPbasOffices();
  },
  methods: {
    ...mapActions("notification", ["show"]),
    addOffice(office) {
      this.loading = true;
      PbasOfficesService.createPbasOffice(office)
        .then(() => {
          this.showNotification("success", `Added ${office.name}`);
          this.loading = false;
          this.getPbasOffices();
          this.addNewOffice = false;
          this.newOffice = Object.assign(this.emptyOffice);
        })
        .catch((err) => {
          this.showNotification(
            "error",
            `Failed to add Office ${office.name}: ${err}`
          );
          this.loading = false;
        });
    },
    getPbasOffices() {
      this.editFooter = false;
      PbasOfficesService.getPbasOffices()
        .then((res) => {
          this.offices = res.data;
        })
        .catch((err) => {
          console.log("Unable to get PBAS offices info: ", err);
        });
    },
    doNotSaveNew() {
      this.editFooter = false;
      this.offices.pop();
    },
    showNotification(type, message) {
      this.loading = false;
      const notification = { type, message };
      this.show(notification);
    },
  },
};
</script>
<style scoped>
p {
  white-space: pre-line;
}
</style>