<template>
  <v-card class="overflow-hidden">
    <v-app>
      <Appbar :links="links" />
      <SideBar :links="links" />
      <SendMessageDrawer
        :contactUs="drawer"
        v-on:close-drawer="drawer = !drawer"
      />
      <v-main>
        <v-btn
          tile
          class="text-subtitle-1 text-capitalize grey white--text contact-us"
          @click.stop="drawer = !drawer"
          fixed
          small
        >
          Leave a message
          <v-icon right dark> mdi-email-send-outline </v-icon>
        </v-btn>
        <v-container fluid class="pa-sm-5 pa-0">
          <slot />
        </v-container>
      </v-main>
      <SnackBar />
      <FooterProvider />
    </v-app>
  </v-card>
</template>

<script>
import Appbar from "../components/Appbar.vue";
import SideBar from "../components/SideBar.vue";
import FooterProvider from "../components/FooterProvider.vue";
import SnackBar from "../components/SnackBar.vue";
import SendMessageDrawer from "../components/SendMessageDrawer.vue";
export default {
  components: { SideBar, FooterProvider, Appbar, SendMessageDrawer, SnackBar },
  data() {
    return {
      drawer: false,
      links: [
        {
          src: require("@/assets/icon_home_w.png"),
          text: "Home",
          route: "/dashboard",
        },
        {
          src: require("@/assets/icon_id_card2_w.png"),
          text: "My Profile",
          route: "/profile",
        },
        {
          src: require("@/assets/icon_money_return.png"),
          text: "New Claim",
          route: "/new-claim",
        },
        {
          src: require("@/assets/icon_search_submissions.png"),
          text: "Search Submissions",
          route: "/search-submissions",
        },
      ],
    };
  },
};
</script>
<style scoped>
.contact-us {
  position: fixed !important;
  right: 0;
  top: 450px;
  transform-origin: bottom right;
  transform: rotate(270deg);
  z-index: 1000;
}
</style>
