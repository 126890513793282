<template>
  <v-card flat class="px-lg-10">
    <v-card-text>
      <v-data-table
        :loading="loading"
        dense
        :headers="headers"
        :items="searchData"
        :options.sync="options"
        :server-items-length="totalCount"
        :footer-props="{ 'items-per-page-options': [10, 15, 30, 50, 100, -1] }"
        show-expand
        item-key="id"
        single-expand
      >
        <template v-slot:item.timestamp="{ item }">
          {{ formatDate(item.timestamp) }}
        </template>

        <template v-slot:item.approvalStatus="{ item }">
          <span
            :class="
              item.approvalStatus === 'Approved'
                ? 'green--text'
                : item.approvalStatus === 'Rejected'
                ? 'red--text'
                : 'black--text'
            "
            >{{ item.approvalStatus }}</span
          >
        </template>

        <template v-slot:item.newValue.providerId="{ item }">
          {{
            item.newValue ? item.newValue.providerId : item.oldValue.providerId
          }}
        </template>

        <template v-slot:item.newValue.providerNumber="{ item }">
          {{
            item.newValue
              ? item.newValue.providerNumber
              : item.oldValue.providerNumber
          }}
        </template>

        <template v-slot:item.approvals="{ item }">
          <div class="d-flex">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="my-1"
                  icon
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                  @click="updateApproval(item, 'Approved')"
                >
                  <v-icon dark> mdi-check </v-icon>
                </v-btn>
              </template>
              <span>approve</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="my-1"
                  icon
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click="updateApproval(item, 'Rejected')"
                >
                  <v-icon dark> mdi-close </v-icon>
                </v-btn>
              </template>
              <span>reject</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td class="px-0" :colspan="headers.length">
            <ApprovalDetail :approval="item" />
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import ApprovalDetail from "./ApprovalDetail.vue";
import ApprovalsService from "../services/ApprovalsService";
import { mapActions } from "vuex";
export default {
  components: { ApprovalDetail },
  props: ["searchData", "totalCount", "loading"],
  data() {
    return {
      expanded: [false],
      options: {},
      tableLoading: false,
      headers: [
        {
          text: "Request Date",
          align: "start",
          value: "timestamp",
          class: "blue--text",
        },
        { text: "Action", value: "action", class: "blue--text" },
        {
          text: "Aproval Status",
          value: "approvalStatus",
          class: "blue--text",
        },
        {
          text: "Name",
          value: "newValue.providerName",
          class: "blue--text",
        },
        {
          text: "Provider Number",
          value: "newValue.providerNumber",
          class: "blue--text",
        },
        { text: "Actions", value: "approvals", class: "blue--text" },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.$emit("pagination", this.options);
      },
      deep: true,
    },
  },
  methods: {
    formatDate(date) {
      const formatedDate = new Date(date).toISOString().split("T");
      //formatedDate[0] = formatedDate[0].split("-").reverse().join("-");
      formatedDate[1] = formatedDate[1].slice(0, -5);
      return formatedDate.join(" ");
    },
    updateApproval(item, action) {
      item.approvalStatus = action;
      ApprovalsService.updateApproval(item.id, item)
        .then(() => {
          this.showNotification("success", `Updated status: ${action}`);
        })
        .catch((err) => {
          this.showNotification("error", `Failed to update status: ${err}`);
        });
    },
    ...mapActions("notification", ["show"]),
    showNotification(type, message) {
      this.loading = false;
      const notification = { type, message };
      this.show(notification);
    },
  },
};
</script>

<style scoped>
</style>