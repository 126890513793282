<template>
  <div>
    <p class="text-subtitle-2 my-2 black--text">
      <strong>Terms and Conditions</strong>
    </p>
    <p class="text-caption black--text">
      This Site is proprietary to PBAS. If you wish to access and use it, you
      must accept and agree to be bound by and comply with these terms (the
      "Terms"). If you do not agree to the Terms, then you must refrain from
      accessing or using the Site. All Terms will apply irrespective of the
      specific use made by users of this Site.
    </p>
    <p class="text-caption black--text">
      You acknowledge and confirm that the Internet is not a secure medium where
      privacy can be ensured, and that complete security and confidentiality
      over the Internet is not possible at this time. Your confidential use of
      the Site cannot be guaranteed and you acknowledge that your use of the
      Site (including information you transmit to the Site) may be subject to
      access by, or disclosure to, other persons. Without limiting any other
      disclaimer herein, PBAS will not be responsible or liable for any harm
      that you or any other person may suffer in connection with any such breach
      of confidentiality or security. However, PBAS has taken reasonable
      measures to protect the confidentiality and security of the private and
      personal information provided on this Site and to ensure that your
      electronic transactions are secure.
    </p>
    <p class="text-caption black--text">
      PBAS may, at any point during which you have activated an account on this
      Site, share your information between the various Plans we administer,
      including on this Site. This applies to your account information and
      direct deposit information, for the purposes of reimbursing you from any
      Plan that PBAS administers.
    </p>
    <p class="text-caption black--text">
      PBAS has the right to verify the accuracy of the information you have
      provided to support a claim. PBAS may request the original receipts and
      any supporting documents within 12 months following the date you submit a
      claim on this Site.
    </p>
    <p class="text-caption black--text">
      As a Registered User of this Site, any request you receive for additional
      information regarding submitted claims, that are not responded to within a
      reasonable timeframe, unless accompanied by an satisfactory explanation,
      may result in your suspension and possible termination as a Registered
      User of this Site.
    </p>
    <p class="text-caption black--text">
      PBAS will issue direct deposits every 2 weeks, which will be accompanied
      by a consolidated Explanation of the Payments issued by each plan sponsor.
      Deposits will be made to the bank the same or following day and will be
      deposited in increments no larger than $5,000.
    </p>
    <p class="text-subtitle-2 my-2 black--text">
      <strong>Registered User Confirmation</strong>
    </p>
    <p class="text-caption black--text">
      I, hereby confirm that the information contained in this Registered User
      Confirmation is true and complete to the best of my knowledge and that,
      the Member(s) for whom I am making a claim is aware that a claim is being
      submitted to PBAS on their behalf. I acknowledge that the Member(s) will
      be notified by email, by PBAS, when payment has been remitted.
    </p>
    <p class="text-caption black--text">
      I understand and authorize that in the event of submission of a suspicious
      or fraudulent claim, PBAS has the right to release the claim to all
      relevant regulatory, investigative or government body, or any other party
      as required by law during the investigation of such fraud.
    </p>
    <p class="text-caption black--text">
      I understand and acknowledge the terms listed above and agree to adhere to
      the business practises set by PBAS.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>