<template>
  <v-container>
    <h1 class="py-0 mb-2 text-h5 font-weight-bold">Provider Portal FAQ’s</h1>
    <v-col class="mt-2">
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">
        How do I add multiple Clinics or Practitioners under the same account?
      </h3>
      <p>
        On your left-hand sidebar click on “My Profile”, and you will see a
        section called “Clinic/Practitioner Information”. If you have one
        business with multiple locations, or one location with multiple
        Practitioners, this can be accommodated here. Simply add each location
        or practitioner separately by clicking on “Add Another
        Clinic/Practitioner”. Each Clinic/Practitioner has the ability to set up
        their own direct deposit information. When submitting a claim, you will
        be asked to select the Clinic/Practitioner to which the claim applies.
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">
        How do I know how much my claim will pay?
      </h3>
      <p>
        In most cases, when your claim is submitted, it is automatically
        adjudicated in our system, and an email is sent to your email address
        with an Explanation of Benefits within minutes. However, some Benefit
        types may not auto-adjudicate, and you will be notified that it has been
        held for Administrator approval.
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">
        When will I receive payment?
      </h3>
      <p>
        PBAS will issue direct deposits no less than twice per month, which will
        be accompanied by a consolidated Explanation of the Payments issued by
        each plan sponsor. Deposits will be made to the bank the same or
        following day and will be deposited in increments no larger than $5,000.
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">
        Do I have to use direct deposit information?
      </h3>
      <p>
        In order to utilize the Provider Portal, direct deposit is mandatory. If
        you do not want to use direct deposit, but your patient wishes to assign
        the benefit to you, your claims must be submitted manually, accompanied
        by an Assignment of Benefits Form. Please contact the Administrator,
        using the information on the bottom of this page, if you require a copy
        of this form.
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">
        How can I update my direct deposit information?
      </h3>
      <p>
        On your left-hand sidebar, click on “My Profile” to update information
        for each Clinic/Practitioner in your account. They must be updated
        individually, as this gives you the option to have different direct
        deposit information if applicable.
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">
        Why is my account change “Pending”?
      </h3>
      <p>
        Your changes will be pended when they relate to adding new
        Clinic/Practitioner or the addition of an Area of Specialization until
        reviewed by the Plan Administrator. This will be reviewed and either
        approved or rejected within 1-2 business days.
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">
        Why was my requested account change rejected?
      </h3>
      <p>
        Your request may be rejected if the Administrator requires more
        information or if they were unable to validate your request. Please
        contact the Administrator, using the information on the bottom of this
        page, if you have any questions.
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">
        How long do I have to submit a claim?
      </h3>
      <p>
        The PBAS Provider Portal allows claim submissions within 30 days of the
        date of service. Once this window as passed, you must submit the claim
        manually. The timeframe to submit claims varies by Plan. Please contact
        the Administrator, using the information on the bottom of this page, for
        more information.
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">
        How can I submit claim types that are not listed?
      </h3>
      <p>
        Claims can only be submitted on the Provider Portal if they are relevant
        to the Plan in which you are submitting the claim. For example, if Plan
        ABC does not offer Chiropractor coverage, you will not see that option
        for members of Plan ABC. If you need to submit such a claim for
        confirmation of rejection, please submit your claim to the Administrator
        manually for review.
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">
        How can I see my previous claims?
      </h3>
      <p>
        On your left-hand sidebar, you can click on “Search Submissions” to
        search for previous claims. Any claims submitted for the searched member
        will display. You can also leave all search areas blank and click
        “Search” and it will display all previous claims submitted from your
        account, in order of most recent. Click on the arrow to expand and view
        each claim.
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">
        Can I submit feedback?
      </h3>
      <p>
        We love to hear your feedback and are always looking to improve our
        system. Please contact the Administrator, using the information on the
        bottom of this page.
      </p>
    </v-col>
  </v-container>
</template>
<script>
export default {};
</script>
<style scoped>
p {
  text-align: justify;
  text-indent: 1em;
}
p.ml-4 {
  text-indent: 0;
}
</style>