<template>
  <v-container>
    <v-card flat>
      <h1 class="mx-auto mb-3 text-h5 text-sm-h4">
        Welcome to the Claims Administrator Page
      </h1>
      <p>
        Claim's Admininstrator can view providers, login as provider
        (impersonate) to act as provider and resovle any issues the provider
        might have. Use Login as Provider button available in provider details
        dialog. <br /><br />Claim's Admininstrator also can view / search Claim
        Submissions.
      </p>
    </v-card>
  </v-container>
</template>
<script>
export default {};
</script>
<style scoped>
p {
  text-align: justify;
}
</style>