<template>
  <v-card flat class="px-lg-10">
    <v-card-text class="pa-0">
      <v-data-table
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :group-desc="true"
        dense
        :headers="headers"
        :items="members"
        item-key="memberId + dob"
        @click:row="memberInfo"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title class="text-subtitle-1"
              >List of found members - <strong>select member</strong> to add
              claim</v-toolbar-title
            >
          </v-toolbar>
        </template>
        <template v-slot:item.dob="{ item }">
          {{ item.providerId ? formatDob(item.dob) : "*******" }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["members"],
  data() {
    return {
      expanded: [false],
      options: {},
      tableLoading: false,
      sortBy: "providerId",
      sortDesc: true,
      headers: [
        {
          text: "Certificate / Member ID",
          align: "start",
          filterable: false,
          value: "memberId",
          class: "blue--text",
        },
        {
          text: "Firstname",
          value: "firstname",
          class: "blue--text",
        },
        { text: "Lastname", value: "lastname", class: "blue--text" },
        {
          text: "Date of Birth",
          value: "dob",
          class: "blue--text",
          sortable: false,
        },
        // { text: "ProviderId", value: "providerId", class: "blue--text" },
      ],
    };
  },
  methods: {
    memberInfo(member) {
      this.$emit("memberSelected", member);
    },
    formatDob(dob) {
      return `${dob.substring(0, 4)}-${dob.substring(4, 6)}-${dob.substring(
        6,
        8
      )}`;
    },
  },
};
</script>

<style>
</style>