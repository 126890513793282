<template>
  <v-container>
    <v-card flat>
      <PbasLoader v-if="loading" />
      <div
        v-html="contentHTML ? contentHTML : defaultContent"
        v-if="!loading"
      ></div>
    </v-card>
  </v-container>
</template>
<script>
import HompageService from "../services/HompageService";
import PbasLoader from "../components/PbasLoader.vue";
import { mapGetters } from "vuex";
export default {
  components: { PbasLoader },
  data() {
    return {
      defaultContent: `<h1 class="mx-auto mb-3 text-h5 text-sm-h4">
        Welcome to the Provider Portal
      </h1>

      <p>
        Our new, easy to use claim system is in the process of being updated,
        offering you a streamlined way to submit claims for members. In the
        meantime, please continue to submit your claims through the New Claim
        button in the side menu.
      </p>`,
      loading: false,
      contentHTML: null,
    };
  },
  beforeMount() {
    this.loading = true;
    HompageService.getHomepage()
      .then((res) => {
        this.contentHTML = res.data.html;
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  mounted() {
    if (
      this.user.profile.role === "Administrator" ||
      this.user.profile.role === "ClaimsAdmin"
    ) {
      this.$router.push({ name: "AdminHome" });
    }

    if (
      !this.user.profile.providerId &&
      this.user.profile.role !== "Administrator" &&
      this.user.profile.role !== "ClaimsAdmin"
    ) {
      this.$router.push({ name: "ProviderRegistration" });
    }
  },
};
</script>
<style scoped>
</style>
