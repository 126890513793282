<template>
  <v-card class="overflow-hidden">
    <v-app-bar
      app
      clippedLeft
      fixed
      extension-height="20"
      height="75"
      :src="require('../assets/default_banner.jpg')"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="90deg, rgba(255,255,255,0.9) 20%, rgba(255,255,255,0) 70%"
        ></v-img>
      </template>
      <div>
        <a href="https://pbas.ca/" target="_blank">
          <v-img
            :src="require('@/assets/pbas_group_logo.svg')"
            content-class="ma-n8 ma-sm-0"
          />
        </a>
      </div>
    </v-app-bar>
  </v-card>
</template>
<script>
export default {};
</script>
<style scoped>
</style>