<template>
  <v-navigation-drawer
    class="drawer"
    v-model="contactUs"
    clipped
    app
    right
    color="#FAFAFA"
    width="300px"
    disable-resize-watcher
    style="z-index: 15; min-height: calc(100vh - 140px)"
  >
    <v-list-item class="light-blue darken-2" dark>
      <v-list-item-icon>
        <v-icon>mdi-email</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Contact us</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>
    <v-container fluid>
      <v-col>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-overlay :value="overlay" :absolute="absolute">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-text-field
            v-model="email.name"
            :rules="nameRules"
            label="Name"
            name="name"
            dense
            required
          ></v-text-field>
          <v-text-field
            v-model="email.email"
            :rules="emailRules"
            label="E-mail"
            email="email"
            dense
            required
          ></v-text-field>
          <v-text-field
            v-model="email.subject"
            :rules="textRules"
            label="Subject"
            name="subject"
            dense
            required
          ></v-text-field>
          <v-textarea
            v-model="email.message"
            :rules="textRules"
            label="Message"
            name="message"
            rows="5"
            row-height="30"
            outlined
            auto-grow
            dense
          >
          </v-textarea>

          <v-btn color="secondary" small class="mr-4" @click="reset" outlined>
            Cancel
          </v-btn>

          <v-btn
            :disabled="!valid"
            color="primary"
            small
            class="mr-4"
            @click="send"
          >
            Submit
          </v-btn>
        </v-form>
      </v-col>
    </v-container>
  </v-navigation-drawer>
</template>
<script>
import { mapActions } from "vuex";
import EmailService from "../services/EmailService";
export default {
  props: ["contactUs"],
  data() {
    return {
      overlay: false,
      absolute: true,
      items: [
        { title: "Home", icon: "mdi-view-dashboard" },
        { title: "About", icon: "mdi-forum" },
      ],
      valid: false,
      name: "",
      nameRules: [(v) => !!v || "Name is required"],
      textRules: [(v) => !!v || "Please enter"],
      idRules: [
        (v) => !!v || "ID is required",
        (v) => (v && v.length <= 10) || "ID must be less than 10 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      school: null,
      schools: [],
      email: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
    };
  },
  methods: {
    ...mapActions("notification", ["show"]),
    reset() {
      this.$emit("close-drawer");
      this.$refs.form.reset();
    },
    send() {
      if (!this.$refs.form.validate()) return;
      this.overlay = true;
      EmailService.send(this.email)
        .then(() => {
          const notification = {
            type: "success",
            message: "Message has been sent",
          };
          this.show(notification);
          this.$emit("close-drawer");
          this.overlay = false;
          this.$refs.form.reset();
        })
        .catch((err) => {
          const notification = {
            type: "error",
            message: `Message not sent: ${err} `,
          };
          this.show(notification);
          this.overlay = false;
        });
    },
  },
};
</script>
<style scoped>
.drawer {
  border-bottom: 1px solid #dcdcdc !important;
}
</style>