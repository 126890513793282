<template>
  <v-container>
    <v-col md="6" class="mx-auto">
      <h1 class="text-h4 text-center">Welcome to new Provider Portal</h1>
      <div class="mx-auto"></div>
    </v-col>
    <v-card flat class="mx-auto">
      <v-card-text class="black--text">
        We have updated our system with new Portal. In order to start using new
        features you need to register at new portal. Your old profile
        information will be pre-filled in new registration form. Please click on
        the link below to start registration. By clicking on the link below you
        agree to accept new terms and conditions.
      </v-card-text>
      <v-card-actions>
        <v-row justify="center">
          <v-btn
            text
            color="blue"
            :href="`${backendUrl}Account/Register?uid=${$route.params.uid}`"
            >Register New Provider
          </v-btn>
        </v-row>
      </v-card-actions>
      <v-card-text>
        <TermsAndConditions />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import TermsAndConditions from "../components/TermsAndConditions.vue";
export default {
  components: { TermsAndConditions },
  data() {
    return {
      backendUrl:
        process.env.VUE_APP_IDENTITY_SERVER_BACKEND_URL ||
        `https://localhost:5001/`,
    };
  },
  mounted() {
    this.backendUrl =
      process.env.VUE_APP_IDENTITY_SERVER_BACKEND_URL ||
      `https://localhost:5001/`;
  },
};
</script>

<style>
</style>