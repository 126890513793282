<template>
  <v-card class="overflow-hidden">
    <v-app>
      <AppbarNoTabs />
      <v-main>
        <v-container fluid>
          <slot />
        </v-container>
      </v-main>
      <SnackBar />
    </v-app>
  </v-card>
</template>

<script>
import AppbarNoTabs from "../components/AppbarNoTabs.vue";
import SnackBar from "../components/SnackBar.vue";
export default {
  components: { AppbarNoTabs, SnackBar },
};
</script>
