<template>
  <v-card flat outlined>
    <v-card-text class="pa-1">
      <v-list-item>
        <v-list-item-content class="py-1">
          <v-row class="text-overline">
            <v-col cols="6">
              {{ claim.Submissionid }}
            </v-col>
            <v-col cols="6" class="ml-12"
              >Date submitted:
              {{
                formatDate(claim.SubmissionDate || date.toISOString())
              }}</v-col
            >
          </v-row>
          <v-row>
            <v-col class="text-body-2 mr-4"
              >Provider Business ID:
              <strong>{{ claim.BusinessID }}</strong>
            </v-col>
            <v-col class="text-body-2 ml-12"
              >Clinic \ Practitioner:
              <strong>{{ claim.BusinessName }}</strong>
            </v-col>
          </v-row>

          <v-list-item-title class="text-h5 mb-1">
            <v-row class="pb-xs-2 pb-sm-0 pt-0">
              <v-col>
                <v-chip class="mr-4">{{ claim.PlanNumber }}</v-chip>
              </v-col>
              <v-col> {{ claim.FirstName }} {{ claim.LastName }}</v-col>
              <v-col class="text-body-2 ml-10"
                >certificate / member ID: {{ claim.StudentId }}
              </v-col>
            </v-row>

            <v-row>
              <v-col class="text-subtitle-2">
                Result of an accident:
                <strong>{{ claim.IsAccident ? "Yes" : "No" }}</strong>
                <span v-if="claim.IsAccident"> | {{ claim.AccidentType }}</span>
              </v-col>
              <v-col class="text-subtitle-1"
                >Total: <strong>${{ claim.ClaimAmountTotal }}</strong>
              </v-col>
            </v-row>
            <div class="text-subtitle-2">Submitted Expenses</div>
            <v-card v-for="expense in claim.Expense" :key="expense.ExpenseId">
              <v-card-text class="pa-1">
                date: <strong>{{ expense.ExpenseDate }}</strong> | patient:
                <strong>{{ expense.PatientName }}</strong> | coverage:
                <strong>{{ expense.coverageType }}</strong> | amount:
                <strong>${{ expense.Amount }}</strong> |
                <span class=""
                  >referral:
                  <small v-if="expense.Comments !== ''">{{
                    expense.Comments
                  }}</small>
                  <span v-else>none</span>
                </span>
              </v-card-text>
            </v-card>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
  </v-card>
</template>

<script>
//import ReferralImageView from "../components/ReferralImageView.vue";
export default {
  //components: { ReferralImageView },
  props: ["claim"],
  data() {
    return {
      referralDialog: false,
      fileExtension: null,
      date: new Date(),
    };
  },
  methods: {
    formatDate(date) {
      return date?.split("T")[0];
    },
    getImageUrl(expense) {
      this.fileExtension = expense.additionalInfoFile
        .split(".")[1]
        .toLowerCase();
      if (this.fileExtension === "pdf") {
        return `data:application/pdf;base64,${expense.base64}`;
      } else {
        return `data:image/${this.fileExtension};base64,${expense.base64}`;
      }
    },
  },
};
</script>

<style>
</style>