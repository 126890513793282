<template>
  <v-row justify="center">
    <v-dialog :value="dialog" @input="$emit('close')" max-width="600px">
      <v-card class="pa-2 flex-grow-1">
        <v-card-text class="pt-0">
          <v-row class="mt-2">
            <v-text-field
              v-model="coverage.name"
              label="Coverage Name"
            ></v-text-field>
          </v-row>
          <v-row class="mt-2">
            <v-text-field
              v-model="coverage.assignedId"
              label="Assigned Id Number"
              :rules="customIdRules"
            ></v-text-field>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click.native="close">
            Close
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click.native="confirmDelete"
            v-show="!isNew"
          >
            Delete
          </v-btn>
          <v-btn color="blue darken-1" text @click.native="saveCoverage">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDialog" max-width="500px">
      <v-card>
        <v-card-title> Are you sure? </v-card-title>
        <v-card-text>
          The coverage
          <span class="primary--text font-weight-bold">{{
            coverage.name
          }}</span>
          will be deleted!
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="confirmDialog = false">
            Cancel
          </v-btn>
          <v-btn color="error" text @click="deleteCoverage"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
    
<script>
export default {
  props: ["dialog", "coverage", "isNew", "coverages"],
  data() {
    return {
      confirmDialog: false,
      customIdRules: [(v) => this.checkIfIdIsUsed(v) || "ID is used"],
    };
  },
  methods: {
    close() {
      this.$emit("close", false);
    },
    confirmDelete() {
      this.confirmDialog = true;
    },
    deleteCoverage() {
      this.confirmDialog = false;
      this.$emit("delete", this.coverage);
    },
    saveCoverage() {
      this.$emit("save", this.coverage);
    },
    checkIfIdIsUsed(id) {
      return (
        this.coverages.filter((a) => a.assignedId === parseInt(id)).length ===
          0 || id !== this.coverage.assignedId
      );
    },
  },
};
</script>

<style>
</style>