<template>
  <v-card class="overflow-hidden">
    <v-app>
      <v-main>
        <v-container fluid>
          <slot />
        </v-container>
      </v-main>
    </v-app>
  </v-card>
</template>

<script>
export default {};
</script>
