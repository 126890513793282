<template>
  <v-container>
    <v-card flat>
      <h1 class="mx-auto mb-3 text-h5 text-sm-h4">
        Registered Providers List (testing)
      </h1>
      <!-- <v-card-title class="py-0">Registered Provider's List</v-card-title> -->
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          clearable
          single-line
          hide-details
          :loading="loading"
        ></v-text-field>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="providers"
        :item-class="getStatusClass"
        :custom-filter="customFilter"
        :footer-props="{
          'items-per-page-options': [10, 15, 30, 50, 100, -1],
        }"
        :search="search"
        @click:row="edit"
        dense
      >
        <template v-slot:[`item.offices`]="{ item }">
          {{ officeNames(item.offices) }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <!-- <div class="d-flex">
            <div v-if="item.isSuspended">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :loading="
                      saving &&
                      providerToActivate.providerId === item.providerId
                    "
                    class="my-1"
                    icon
                    color="green"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="activateProvider(item)"
                  >
                    <v-icon dark> mdi-refresh-auto </v-icon>
                  </v-btn>
                </template>
                <span>activate account</span>
              </v-tooltip>
            </div>
            <div v-if="!item.isSuspended">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :loading="
                      saving &&
                      providerToSuspend.providerId === item.providerId
                    "
                    class="my-1"
                    icon
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="suspend(item)"
                  >
                    <v-icon dark> mdi-pause-octagon-outline </v-icon>
                  </v-btn>
                </template>
                <span>suspend account</span>
              </v-tooltip>
            </div>
          </div> -->
          <td class="px-0">
            <AccountStatusSelect
              :item="item"
              @set-status="setProviderStatus"
              :saving="
                saving && selectedProvider.providerId === item.providerId
              "
            />
          </td>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="open" max-width="1200">
      <ProviderDetail
        :provider="provider"
        @closeDialog="open = false"
        :loading="loading"
      />
    </v-dialog>

    <v-dialog width="360" v-model="suspendDialog">
      <v-card>
        <v-card-title>Are you sure?</v-card-title>
        <v-card-text
          >Provider <strong>{{ providerToSuspend.providerName }}</strong
          >'s account will be suspended! The provider will not be able to submit
          any claims!</v-card-text
        >
        <v-card-actions class="justify-end">
          <v-btn @click="cancelSuspend">Cancel</v-btn>
          <v-btn @click="adminMessageDialog = true" color="primary"
            >Suspend</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="400" v-model="adminMessageDialog">
      <v-card>
        <v-card-title>Add optional message to provider</v-card-title>
        <v-card-text class="pb-0">
          <v-textarea
            clearable
            no-resize
            outlined
            rows="3"
            auto-grow
            v-model="adminMessage"
            label="Message"
            placeholder="Enter message - reason"
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="justify-end pt-0 pb-4">
          <v-btn @click="cancelSuspend">Cancel</v-btn>
          <v-btn @click="suspendProvider" color="primary">Suspend</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import ProviderService from "../services/ProviderService";
import ProviderDetail from "../components/ProviderDetail.vue";
import AccountStatusSelect from "../components/AccountStatusSelect.vue";
import { mapActions } from "vuex";
export default {
  components: { ProviderDetail, AccountStatusSelect },
  data() {
    return {
      search: "",
      loading: false,
      providers: [],
      provider: {},
      open: false,
      suspendDialog: false,
      providerToSuspend: {},
      providerToActivate: {},
      selectedProvider: {},
      saving: false,
      adminMessageDialog: false,
      adminMessage: null,
      headers: [
        // {
        //   text: "",
        //   value: "isSuspended",
        //   class: "primary--text px-0",
        //   sortable: false,
        //   cellClass: "px-0",
        // },
        {
          text: "Name",
          value: "providerName",
          class: "primary--text",
        },
        {
          text: "Provider Number",
          value: "providerNumber",
          class: "primary--text",
        },
        {
          text: "Registration Number",
          value: "registrationNumber",
          class: "primary--text",
        },
        {
          text: "Offices",
          value: "offices",
          class: "primary--text",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary--text",
          width: "65px",
        },
      ],
    };
  },
  mounted() {
    this.loadProviders();
  },
  methods: {
    ...mapActions("notification", ["show"]),
    showNotification(type, message) {
      this.loading = false;
      const notification = { type, message };
      this.show(notification);
    },

    customFilter(value, search) {
      let result =
        value && value.toString().toLowerCase().includes(search.toLowerCase());
      if (typeof value === "object") {
        result =
          value.filter((office) =>
            office.officeName.toLowerCase().includes(search.toLowerCase())
          ).length > 0;
      }
      return result;
    },

    loadProviders() {
      this.loading = true;
      ProviderService.getProviders()
        .then((res) => {
          this.loading = false;
          this.providers = res.data;
          this.totalCount = parseInt(res.headers["x-total-count"]);
        })
        .catch((err) => {
          this.loading = false;
          this.showNotification(
            "error",
            `Failed to load providers list: ${err}`
          );
        });
    },
    officeNames(offices) {
      let names = "";
      offices.forEach((office) => {
        names += office.officeName + ", ";
      });
      return names.slice(0, -2);
    },
    edit(provider) {
      this.loading = true;
      ProviderService.getProfile(provider.providerId)
        .then((res) => {
          this.loading = false;
          this.provider = res.data;
          this.open = true;
        })
        .catch((err) => {
          this.showNotification(
            "error",
            `Failed to load provider's profile: ${err}`
          );
        });
    },
    getStatusClass(provider) {
      return provider.isSuspended ? "orange lighten-5" : "";
    },
    setProviderStatus(provider) {
      if (provider.isSuspended) {
        this.suspend(provider);
      } else {
        this.activateProvider(provider);
      }
    },
    suspend(provider) {
      this.selectedProvider = provider;
      this.providerToSuspend = provider;
      this.suspendDialog = true;
    },
    suspendProvider() {
      this.saving = true;
      this.providerToSuspend.adminMessage = this.adminMessage;
      ProviderService.suspend(this.providerToSuspend)
        .then((res) => {
          this.saving = false;
          console.log("suspended provider:", res.data);
          this.providerToSuspend = {};
          this.adminMessage = "";
          this.suspendDialog = false;
          this.adminMessageDialog = false;
          this.showNotification(
            "success",
            `Provider's account has been suspended`
          );
          this.loadProviders();
        })
        .catch((err) => {
          this.saving = false;
          this.providerToSuspend = {};
          this.showNotification("error", `Failed to suspend provider: ${err}`);
        });
    },
    cancelSuspend() {
      this.suspendDialog = false;
      this.adminMessageDialog = false;
      this.providerToSuspend = {};
      this.selectedProvider = {};
      this.loadProviders();
    },
    activateProvider(provider) {
      this.selectedProvider = provider;
      this.providerToActivate = provider;
      this.saving = true;
      ProviderService.activate(provider.providerId)
        .then((res) => {
          this.saving = false;
          console.log("activated provider:", res.data);
          this.providerToActivate = {};
          this.suspendDialog = false;
          this.showNotification(
            "success",
            `Provider's account has been activated`
          );
          this.loadProviders();
        })
        .catch((err) => {
          this.saving = false;
          this.providerToActivate = {};
          this.showNotification("error", `Failed to activate provider: ${err}`);
        });
    },
  },
};
</script>
<style scoped>
p {
  text-align: justify;
}
.v-tooltip__content {
  max-width: 400px !important;
}
</style>