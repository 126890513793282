<template>
  <v-snackbar
    v-model="showSnackBar"
    :color="notification.type"
    :multi-line="multiLine"
    :timeout="closeBtn"
    content-class="white--text text-h6 text-center"
  >
    {{ notification.message }}
    <template v-slot:action="{ attrs }">
      <v-btn icon @click="remove" v-bind="attrs" v-if="closeBtn === -1">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data: () => ({
    multiLine: true,
  }),
  computed: {
    ...mapState("notification", ["show", "closeBtn", "notification"]),
    showSnackBar: {
      get() {
        return this.show;
      },
      set(value) {
        this.remove();
        return value;
      },
    },
  },
  methods: {
    ...mapActions("notification", ["remove"]),
  },
};
</script>

<style scoped>
.snackbar {
  color: rgba(0, 0, 0, 0.5);
}
</style>