<template>
  <v-container>
    <v-col md="6" class="mx-auto">
      <h1 class="text-h4 mb-6 text-center">Redirecting to Providers Portal</h1>
      <div class="mx-auto">
        <PbasLoader />
      </div>
    </v-col>
  </v-container>
</template>

<script>
import AuthService from "../services/AuthService";
import PbasLoader from "../components/PbasLoader.vue";
export default {
  components: { PbasLoader },
  mounted() {
    this.loginIdentityServer();
  },
  methods: {
    async loginIdentityServer() {
      await AuthService.login();
    },
  },
};
</script>
