<template>
  <v-container>
    <v-card flat class="px-12 d-flex justify-center mb-2">
      <h1 class="text-h5">Health Provider Registration</h1>
    </v-card>
    <v-card flat class="mx-auto">
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="(e) => saveProfile(e)"
      >
        <v-expansion-panels focusable tile multiple :value="expanded">
          <v-expansion-panel>
            <v-expansion-panel-header>
              Provider Information
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="pt-2 pb-5" flat>
                <v-row>
                  <v-col cols="12" md="6" sm="5">
                    <v-text-field
                      class="mt-2"
                      dense
                      v-model="provider.providerName"
                      :rules="textRules"
                      label="Name"
                      name="providerName"
                      hint="Please Enter Provider's Name"
                      required
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" sm="3">
                    <v-text-field
                      class="mt-2"
                      dense
                      v-model="provider.registrationNumber"
                      :rules="textRules"
                      label="Registration Number"
                      name="registrationNumber"
                      hint="Please provide if applicable."
                      type="number"
                      required
                    ></v-text-field>
                  </v-col> -->
                  <v-col cols="12" sm="6">
                    <v-text-field
                      class="mt-2"
                      dense
                      v-model.trim="provider.providerNumber"
                      label="Provider Number"
                      hint="Provider Number will be generated or prefilled on approval"
                      persistent-hint
                      name="providerNumber"
                      disabled
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <h2 class="text-h6">Clinic/Practitioner Information</h2>
                <v-expansion-panels focusable tile multiple :value="expanded1">
                  <ProfileProviderOffice
                    v-for="(n, i) in officeIds"
                    :key="n"
                    :officeStartId="n"
                    :office="offices[i]"
                    :email="username"
                  >
                    <v-btn
                      tile
                      :disabled="officeIds.length <= 1"
                      color="secondary"
                      class="mr-4"
                      @click="removeOffice(n)"
                    >
                      REMOVE
                    </v-btn>
                  </ProfileProviderOffice>
                </v-expansion-panels>
              </v-card>
              <v-btn tile color="secondary" class="ml-4 mb-4" @click="addOffice"
                >Add Another Clinic/Practitioner</v-btn
              >
              <v-divider></v-divider>

              <!-- Provider Benefits -->
              <v-card class="pa-0" flat>
                <v-card-title class="text-h6"
                  >Areas of Specialization
                  <small v-if="checkboxNotSelected" class="ml-4 red--text">
                    please select at least one coverage</small
                  ></v-card-title
                >
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                      v-for="(area, index) in areasOfSpecialization"
                      :key="area.coverageId"
                      class="ma-0 pa-0"
                    >
                      <v-checkbox
                        :label="area.name"
                        :value="area"
                        v-model="provider.coverages"
                        true-value
                        color="primary"
                        :hide-details="
                          index !== areasOfSpecialization.length - 1
                        "
                        name="coverages"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-divider class="mb-2"></v-divider>
              <!-- Terms and conditions -->
              <v-checkbox
                v-model="provider.verifyProviderRegistration"
                hide-details
                label="Verify Provider Registration"
                name="verifyProviderRegistration"
                required
                :rules="verifyRule"
                :value="true"
                class="mt-4"
              ></v-checkbox>

              <TermsAndConditions />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-card-actions>
          <v-btn
            tile
            color="secondary"
            class="mt-4 mb-6"
            type="submit"
            :loading="isLoading"
            >SAVE</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import ProfileProviderOffice from "../components/ProfileProviderOffice.vue";
import { mapActions, mapGetters } from "vuex";
import CoveragesService from "../services/CoveragesService";
import ProviderService from "../services/ProviderService";
import TermsAndConditions from "../components/TermsAndConditions.vue";

export default {
  components: {
    ProfileProviderOffice,
    TermsAndConditions,
  },
  data() {
    return {
      valid: false,
      expanded: [0],
      expanded1: [0],
      isLoading: false,
      officeId: 1,
      officeIds: [1],
      coverages: [],
      areasOfSpecialization: [],
      offices: [],
      provider: {
        registrationNumber: "",
        providerNumber: "",
        providerName: "",
        officeId: "",
        phoneNumber: "",
        phoneExtension: "",
        address: "",
        city: "",
        province: "",
        postalCode: "",
        coverages: [],
        primaryContactFirstname: "",
        primaryContactLastname: "",
        institutionNumber: "",
        transitNumber: "",
        accountType: "Chequing",
        accountNumber: "",
        verifyDirectDeposit: false,
        verifyProviderRegistration: false,
        directDepositEmail: "",
        username: "",
        email: "",
        password: "",
        newPassword: "",
        confirmPassword: "",
        file: null,
        securityQuestion: "",
        securityAnswer: "",
        timezone:
          Intl.DateTimeFormat().resolvedOptions().timeZone +
          " " +
          new Date().toString(),
      },
      textRules: [
        (v) => !!v || "Please Enter",
        // (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      regNumberRules: [
        (v) => /\d$/.test(v) || "Registration number must be valid",
      ],
      verifyRule: [(v) => !!v || "Please verify"],
      timezone:
        Intl.DateTimeFormat().resolvedOptions().timeZone +
        " " +
        new Date().toString(),
    };
  },
  watch: {
    officeIds: function () {
      this.validate();
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    username() {
      if (this.$store.getters["auth/user"]) {
        return this.$store.getters["auth/user"].profile.username;
      }
      return "";
    },
    checkboxNotSelected() {
      return this.provider.coverages.length === 0;
    },
  },
  mounted() {
    this.getProvinces();
    if (this.user.profile.oldUid) {
      ProviderService.getOldUidProfile(this.user.profile.oldUid)
        .then((res) => this.parseOldUidProfile(res.data))
        .catch((err) => console.log(err));
    }
    CoveragesService.getCoverages()
      .then((res) => {
        this.areasOfSpecialization = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    ...mapActions("notification", ["show"]),
    ...mapActions("enums", ["getProvinces"]),
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    addOffice() {
      this.officeId++;
      this.officeIds.push(this.officeId);
    },
    removeOffice(id) {
      const index = this.officeIds.indexOf(id);
      this.officeIds.splice(index, 1);
    },
    parseOldUidProfile(oldProfile) {
      console.log("old uid profile: ", oldProfile);
      if (!oldProfile) return;
      this.provider.providerName = oldProfile.name;
      this.provider.registrationNumber = oldProfile.register_number;
      this.provider.providerNumber = oldProfile.provider_number;
      if (oldProfile.offices && oldProfile.offices.length > 0) {
        oldProfile.offices.forEach((office) => {
          const officeLocation = {};
          officeLocation.address = office.address;
          officeLocation.city = office.city;
          officeLocation.businessID = office.businessid;
          officeLocation.businessName = office.clinicname;
          officeLocation.directDepositEmail = office.email;
          officeLocation.phoneExtension = office.extension ?? "";
          officeLocation.officeName = office.clinicname;
          officeLocation.phoneNumber = office.phone;
          officeLocation.postalCode = office.postal;
          officeLocation.province = office.province;
          officeLocation.accountNumber = office.directdeposit.account;
          officeLocation.institutionNumber =
            office.directdeposit.institution.padStart(3, "0");
          officeLocation.transitNumber = office.directdeposit.transit;
          this.offices.push(officeLocation);
          this.officeId++;
          this.officeIds.push(this.officeId);
        });
      }
    },

    saveProfile(e) {
      this.$refs.form.validate();
      if (!this.valid || this.provider.coverages.length === 0) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      this.isLoading = true;
      const formData = new FormData(e.target);
      const payload = {
        providerId: this.provider.providerId,
        offices: [],
      };
      let isOffice = false;
      let currentOffice = null;
      for (var pair of formData.entries()) {
        let [key, value] = pair;
        if (key === "officeStartId" && value !== currentOffice) {
          isOffice = true;
          currentOffice = value;
          payload.offices.push({
            verifyDirectDeposit: false,
          });
          continue;
        }
        if (key == "officeEnd") {
          isOffice = false;
          continue;
        }
        if (isOffice) {
          const lastIndex = payload.offices.length - 1;
          console.log("key: ", key, "value: ", value);
          if (key == "officeId" && value == "") continue;
          if (key == "coverages") continue;
          if (key == "verifyDirectDeposit") {
            payload.offices[lastIndex][key] = value == "true" ? true : false;
            continue;
          }
          payload.offices[lastIndex][key] = value;
        } else {
          if (key == "registrationNumber") {
            value = parseInt(value);
          }
          payload[key] = value;
        }
      }

      payload.file = payload.file?.name;
      payload.coverages = this.provider.coverages;
      payload.verifyProviderRegistration =
        this.provider.verifyProviderRegistration ?? false;
      console.log("payload: ", payload);
      ProviderService.createProfile(payload)
        .then(() => {
          this.isLoading = false;
          this.reset();
          const notification = {
            type: "success",
            message: "Provider Registered, please login",
          };
          this.show(notification);
          this.loading = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
          setTimeout(() => {
            this.$router.push({ name: "login" });
          }, 5000);
        })
        .catch((err) => {
          const notification = {
            type: "error",
            message: `Unable to create profile! ${err.message}`,
          };
          this.show(notification);
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.v-input--selection-controls {
  margin: 0 !important;
  padding: 0 !important;
}
</style>