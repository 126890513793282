import EnumsService from "../../services/EnumsService";

export const namespaced = true;

export const state = {
  provinces: [],
};

export const mutations = {
  SET_PROVINCES(state, provinces) {
    const other = provinces.find((p) => p.code === "OT");
    const provinceList = provinces.filter((p) => p.code !== "OT");
    provinceList.sort((a, b) =>
      a.name > b.name ? 1 : a.name === b.name ? 0 : -1,
    );
    provinceList.push(other);
    state.provinces = provinceList;
  },
};

export const actions = {
  getProvinces({ commit }) {
    EnumsService.getProvinces()
      .then(({ data }) => {
        commit("SET_PROVINCES", data);
      })
      .catch((err) => {
        console.log(err);
        const provinces = [
          {
            code: "OT",
            location: "Eastern Standard Time",
            id: "5decc3f7-303f-4ff0-a729-0f1b5f0a360a",
            name: "Other",
          },
          {
            code: "AB",
            location: "Mountain Standard Time",
            id: "a9128959-8107-ea11-91f4-1c1b0d40c21b",
            name: "Alberta",
          },
          {
            code: "BC",
            location: "Pacific Standard Time",
            id: "aa128959-8107-ea11-91f4-1c1b0d40c21b",
            name: "British Columbia",
          },
          {
            code: "MB",
            location: "Central Standard Time",
            id: "ab128959-8107-ea11-91f4-1c1b0d40c21b",
            name: "Manitoba",
          },
          {
            code: "NB",
            location: "Atlantic Standard Time",
            id: "ac128959-8107-ea11-91f4-1c1b0d40c21b",
            name: "New Brunswick",
          },
          {
            code: "NL",
            location: "Newfoundland Standard Time",
            id: "ad128959-8107-ea11-91f4-1c1b0d40c21b",
            name: "Newfoundland and Labrador",
          },
          {
            code: "NS",
            location: "Atlantic Standard Time",
            id: "ae128959-8107-ea11-91f4-1c1b0d40c21b",
            name: "Nova Scotia",
          },
          {
            code: "NT",
            location: "Mountain Standard Time",
            id: "af128959-8107-ea11-91f4-1c1b0d40c21b",
            name: "Northwest Territories",
          },
          {
            code: "NU",
            location: "Eastern Standard Time",
            id: "b0128959-8107-ea11-91f4-1c1b0d40c21b",
            name: "Nunavut",
          },
          {
            code: "ON",
            location: "Eastern Standard Time",
            id: "b1128959-8107-ea11-91f4-1c1b0d40c21b",
            name: "Ontario",
          },
          {
            code: "PE",
            location: "Atlantic Standard Time",
            id: "b2128959-8107-ea11-91f4-1c1b0d40c21b",
            name: "Prince Edward Island",
          },
          {
            code: "QC",
            location: "Eastern Standard Time",
            id: "b3128959-8107-ea11-91f4-1c1b0d40c21b",
            name: "Quebec",
          },
          {
            code: "SK",
            location: "Central Standard Time",
            id: "b4128959-8107-ea11-91f4-1c1b0d40c21b",
            name: "Saskatchewan",
          },
          {
            code: "YT",
            location: "Mountain Standard Time",
            id: "b5128959-8107-ea11-91f4-1c1b0d40c21b",
            name: "Yukon",
          },
        ];
        commit("SET_PROVINCES", provinces);
      });
  },
};

export const getters = {
  provinces(state) {
    return state.provinces;
  },
};
