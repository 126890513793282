<template>
  <v-card
    max-width="400"
    class="pa-2 flex-grow-1"
    hover
    link
    @click="edit"
    :loading="loading"
  >
    <v-card-text class="pt-0">
      <v-row class="mt-2 mb-0" no-gutters>
        <div class="plan-details">
          <div
            v-if="plan.isDrawbridgePlan"
            class="success--text font-weight-medium"
          >
            Drawbridge Plan
          </div>
          <div v-if="!plan.isDrawbridgePlan">Plan:</div>
          <small>{{
            plan.effectiveDate && plan.effectiveDate.slice(0, 10)
          }}</small>
        </div>
        <v-chip color="primary" class="text-h6 mr-0 ml-auto">
          {{ plan.planName }} {{ plan.planName ? " - " : "" }}
          {{ plan.planNumber }}
        </v-chip>
      </v-row>
      <v-row class="mt-0">
        <v-col
          cols="12"
          sm="6"
          v-for="coverage in plan.coverages"
          :key="coverage.coverageID"
          class="ma-0 pa-0"
        >
          <v-checkbox
            :label="coverage.name"
            :value="coverage"
            v-model="plan.coverages"
            color="primary"
            dense
            readonly
            hide-details
            name="coverages"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["plan", "loading"],
  methods: {
    edit() {
      this.$emit("edit", this.plan);
    },
  },
};
</script>

<style scoped>
.plan-details {
  line-height: 1;
}
</style>