import axios from 'axios';

const apiClient = axios.create({
  baseURL:
    process.env.VUE_APP_PROVIDERS_BACKEND_URL || `http://localhost:44300/api`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    // 'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

export default {
  async get(url) {
    const res = await apiClient.get(url);
    return res;
  },

  async post(url, body) {
    return await apiClient.post(url, body);
  },

  async put(url, body) {
    return await apiClient.put(url, body);
  },

  async delete(url) {
    return await apiClient.delete(url);
  },

  async setAuth(token) {
    apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  },

  async getNoAuth(url) {
    const config = {};
    const instance = axios.create(config);
    instance.defaults.headers.common = {};
    instance.defaults.headers.common.accept = 'application/json';
    return await instance.get(url);
  },
};
