<template>
  <v-container>
    <v-card flat max-width="450" class="mx-auto mt-10">
      <v-row>
        <v-col>
          <v-img
            :src="require('@/assets/pbas_group_logo.svg')"
            width="400"
            contain
            class="float-left"
          />
        </v-col>
      </v-row>
      <v-col class="mt-2">
        <p>We are currently undergoing unscheduled maintenance.</p>
        <p>We apologize for the inconvenience. We will be back shortly.</p>
        <p>
          If you have questions, concerns, or inquires please contact the
          Administrator at: <a href="tel:1-800-563-1930">1-800-563-1930</a>.
        </p>

        <p>Thank you for your patience and understanding.</p>
        <p>The PBAS Group</p>
      </v-col>
    </v-card>
  </v-container>
</template>
<script>
export default {};
</script>
<style scoped>
p {
  text-align: justify;
}
</style>