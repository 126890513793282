<template>
  <v-select
    flat
    v-model="item.claimSubmissionLog.status"
    :disabled="!isAdmin"
    :items="statuses"
    item-value="value.status"
    @change="setStatus(item)"
    :background-color="getStatusClass(item)"
    :append-icon="isAdmin ? icon : ''"
    :loading="loading"
    hide-details
    dense
    solo
  >
    <template #selection="">
      <v-chip
        small
        :class="getChipClass(item.claimSubmissionLog) + ' lighten-3 caption'"
        >{{ getStatus(item.claimSubmissionLog) }}
      </v-chip>
    </template>
  </v-select>
</template>

<script>
import ClaimService from "../services/ClaimService";
import { mapGetters } from "vuex";
export default {
  props: ["item"],
  data() {
    return {
      icon: "mdi-some",
      loading: false,
      statuses: [
        { text: "Accepted", value: { status: "A" } },
        { text: "Error", value: { status: "E" } },
        { text: "Pending", value: { status: "P" } },
        { text: "Rejected", value: { status: "R" } },
      ],
    };
  },
  mounted() {
    this.icon = this.isAdmin ? "mdi-menu-down" : "mdi-some";
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    isAdmin() {
      return (
        this.user.profile.role === "Administrator" ||
        this.user.profile.role === "ClaimsAdmin"
      );
    },
  },
  methods: {
    formatDate(date) {
      return date.split("T")[0];
    },
    getProviderName(id) {
      const provider = this.providers.filter((p) => p.providerId === id)[0];
      if (provider && provider.providerName) {
        return provider.providerName;
      } else {
        return null;
      }
    },
    getStatus(log) {
      if (log && log.status) {
        switch (log.status) {
          case "A":
            return "Accepted";
          case "R":
            return "Rejected";
          case "E":
            return "Error";
          default:
            return "Pending";
        }
      }
    },
    setStatus(claim) {
      this.loading = true;
      claim.Status = claim.claimSubmissionLog.status;
      ClaimService.setClaimStatus(claim.claimId, claim)
        .then(() => {
          this.icon = "mdi-check";
          setTimeout(() => {
            this.icon = "mdi-menu-down";
          }, 3000);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.icon = "mdi-sync-alert";
          setTimeout(() => {
            this.icon = "mdi-menu-down";
          }, 3000);
        });
    },
    getStatusClass(item) {
      if (item.claimSubmissionLog) {
        switch (item.claimSubmissionLog.status) {
          case "A":
            return "green lighten-5";
          case "R":
            return "yellow lighten-5";
          case "E":
            return "orange lighten-5";
          default:
            return "grey lighten-5";
        }
      }
    },
    getChipClass(item) {
      if (item.status) {
        switch (item.status) {
          case "A":
            return "green";
          case "R":
            return "yellow";
          case "E":
            return "orange";
          default:
            return "grey";
        }
      }
    },
  },
  watch: {
    tableOptions: {
      handler() {
        this.$emit("pagination", this.tableOptions);
        this.expanded = [];
      },
      deep: true,
    },
    searchData: {
      handler() {
        this.expanded = [];
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.v-input__slot {
  padding: 0 !important;
}
</style>