import api from '../../services/api';

export const namespaced = true;

export const state = {
  user: {
    Username: null,
    Name: null,
    Role: null,
    Token: null,
  },
};

export const mutations = {
  SET_USER_DATA(state, userData) {
    state.user = userData;
  },
  CLEAR_USER_DATA() {
    state.user = null;
  },
};

export const actions = {
  register({ commit }, credentials) {
    return api.post('/register', credentials).then(({ data }) => {
      commit('SET_USER_DATA', data);
    });
  },
  setUser({ commit, dispatch }, user) {
    commit('SET_USER_DATA', user);
    if (!user) return;
    api.setAuth(user.access_token);
    if (
      user.profile &&
      user.profile.role === 'Provider' &&
      user.profile.providerId
    ) {
      dispatch('provider/getProfile', user.profile.providerId, { root: true });
    }
  },
  logout({ commit }) {
    commit('CLEAR_USER_DATA');
  },
};

export const getters = {
  user(state) {
    return state.user;
  },
  isLoggedIn(state) {
    return !!state.user;
  },
};
