import api from './api';

export default {
  async getPbasOffices() {
    return await api.get(`/pbasOffices`);
  },

  async createPbasOffice(plan) {
    return await api.post(`/pbasOffices`, plan);
  },

  async updatePbasOffice(id, update) {
    return await api.put(`/pbasOffices/${id}`, update);
  },

  async deletePbasOffice(id) {
    return await api.delete(`/pbasOffices/${id}`);
  },
};
