import { UserManager, WebStorageStateStore } from 'oidc-client';

var config = {
  authority:
    process.env.VUE_APP_IDENTITY_SERVER_BACKEND_URL ||
    'https://localhost:5001/',
  client_id: 'vuejs-client',
  redirect_uri:
    process.env.VUE_APP_PROVIDERS_FRONTEND_REDIRECT_URI ||
    'http://localhost:8080/signin-callback.html',
  post_logout_redirect_uri:
    process.env.VUE_APP_PROVIDERS_FRONTEND_LOGOUT_URI ||
    'http://localhost:8080/',
  monitorSession: false,
  response_type: 'code', // for Auth Code flow
  scope: 'providers.api write read openid profile email',
  userStore: new WebStorageStateStore({ store: window.localStorage }), // set this to save user info in localStorage
  accessTokenExpiringNotificationTime: 10,
};

var mgr = new UserManager(config);
// Log.logger = console;
// Log.level = Log.INFO;

export default {
  async login() {
    return mgr.signinRedirect();
  },
  async getUser() {
    return mgr.getUser();
  },
  handleUser(user) {
    if (user.profile.role === undefined) {
      return this.logout();
    }
  },
  async logout() {
    await mgr.removeUser();
    return mgr.signoutRedirect();
  },
};
