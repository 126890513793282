import MemberService from '../../services/MemberService';

export const namespaced = true;

export const state = {
  member: {
    memberId: null,
    plan: null,
    firstname: null,
    lastname: null,
    dob: null,
    email: null,
    address: null,
    city: null,
    province: null,
    postalCode: null,
    phoneNumber: null,
    comments: null,
    dependants: [
      {
        name: null,
        relationship: null,
        dob: null,
      },
    ],
  },
};

export const mutations = {
  RESET_MEMBER_PROFILE(state) {
    state.member = {
      memberId: null,
      plan: null,
      firstname: null,
      lastname: null,
      dob: null,
      email: null,
      address: null,
      city: null,
      province: null,
      postalCode: null,
      phoneNumber: null,
      comments: null,
      dependants: [
        {
          name: null,
          relationship: null,
          dob: null,
        },
      ],
    };
  },
  SET_MEMBER_PROFILE(state, profile) {
    state.member = profile;
  },
};

export const actions = {
  getMemberProfile({ commit }, memberId) {
    MemberService.getProfile(memberId).then(({ data }) => {
      if (data.length) {
        commit('SET_MEMBER_PROFILE', data[0]);
      } else commit('SET_MEMBER_PROFILE', {});
    });
  },
  resetMemberProfile({ commit }) {
    commit('RESET_MEMBER_PROFILE');
  },
};
