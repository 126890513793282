<template>
  <v-card class="overflow-hidden">
    <v-app>
      <AppbarNoButtons />
      <v-main>
        <v-container fluid>
          <slot />
        </v-container>
      </v-main>
    </v-app>
  </v-card>
</template>

<script>
import AppbarNoButtons from "../components/AppbarNoButtons.vue";
export default {
  components: { AppbarNoButtons },
};
</script>
