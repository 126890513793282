<template>
  <v-container>
    <v-row>
      <h1 class="py-0 mb-3 mx-2 text-h5 mt-3">Edit coverages</h1>
      <v-btn
        tile
        color="secondary"
        class="ma-2 ml-sm-auto"
        type="submit"
        @click="newCoverageDialog = true"
        :loading="loading"
        >ADD NEW</v-btn
      >
    </v-row>
    <v-item-group>
      <v-row>
        <v-col
          cols="12"
          sm="4"
          lg="3"
          v-for="coverage in coverages"
          :key="coverage.coverageId"
          class="d-flex pa-2"
          style="flex-direction: column"
        >
          <v-item>
            <Coverage
              :coverage="coverage"
              @edit="editCoverage"
              :loading="loading"
            />
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>
    <EditCoverageForm
      :dialog="dialog"
      :isNew="false"
      :coverage="coverageToEdit"
      @close="closeDialog"
      @delete="deleteCoverage"
      @save="saveCoverage"
      :coverages="coverages"
    />

    <EditCoverageForm
      :dialog="newCoverageDialog"
      :isNew="true"
      :coverage="newCoverage"
      title="Create new coverage"
      @close="closeDialog"
      @save="createCoverage"
      :coverages="coverages"
    />
  </v-container>
</template>

<script>
import CoveragesService from "../services/CoveragesService";
import Coverage from "../components/Coverage.vue";
import EditCoverageForm from "../components/EditCoverageForm.vue";
import { mapActions } from "vuex";
export default {
  components: { Coverage, EditCoverageForm },
  data() {
    return {
      coverages: [],
      dialog: false,
      newCoverageDialog: false,
      coverageToEdit: {},
      loading: false,
      valid: false,
      newCoverage: {},
    };
  },
  created() {
    this.loading = true;
    this.loadCoverages();
  },
  methods: {
    ...mapActions("notification", ["show"]),
    editCoverage(coverage) {
      this.coverageToEdit = coverage;
      this.dialog = true;
    },
    deleteCoverage(coverage) {
      this.loading = true;
      this.dialog = false;
      CoveragesService.deleteCoverage(coverage.coverageId)
        .then(() => {
          this.showNotification(
            "success",
            `Deleted coverage: ${coverage.name}`
          );
          this.loadCoverages();
        })
        .catch((err) => {
          this.showNotification("error", `Failed to delete coverage: ${err}`);
        });
    },
    createCoverage(coverage) {
      this.loading = true;
      this.newCoverageDialog = false;
      CoveragesService.createCoverage(coverage)
        .then(() => {
          this.newCoverage = {};
          this.showNotification("success", "New coverage created");
          this.loadCoverages();
        })
        .catch((err) => {
          this.showNotification("error", `Failed to create coverage: ${err}`);
        });
    },
    closeDialog() {
      this.newCoverageDialog = false;
      this.dialog = false;
      this.loadCoverages();
    },

    saveCoverage(coverage) {
      this.loading = true;
      this.dialog = false;
      CoveragesService.updateCoverage(coverage.coverageId, coverage)
        .then(() => {
          this.showNotification(
            "success",
            `Updated coverage: ${coverage.name}`
          );
          this.loadCoverages();
        })
        .catch((err) => {
          this.showNotification("error", `Failed to update coverage: ${err}`);
        });
    },

    showNotification(type, message) {
      this.loading = false;
      const notification = { type, message };
      this.show(notification);
    },
    loadCoverages() {
      this.loading = true;
      CoveragesService.getCoverages()
        .then((res) => {
          this.coverages = res.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>