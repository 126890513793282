import api from './api';

export default {
  async getHomepage() {
    return await api.get(`/homepage`);
  },

  async createHomepage(html) {
    return await api.post(`/homepage`, html);
  },

  async updateHomepage(update) {
    if (update.id) {
      return await api.put(`/homepage/${update.id}`, update);
    } else return await api.post(`/homepage`, update);
  },

  async deleteHomepage(id) {
    return await api.delete(`/homepage/${id}`);
  },
};
