<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th>Field</th>
            <th>Old Data</th>
            <th><span class="red--text">New Data</span></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, i) in Object.keys(
              approvalNewValue ? approvalNewValue : approvalOldValue
            )"
            :key="i"
          >
            <td>
              <span class="primary--text">{{ formatFieldNames(row) }}:</span>
            </td>
            <td>
              <span v-if="row == 'offices'">
                <ApprovalOfficeDetail
                  :offices="approvalOldValue ? approvalOldValue[row] : {}"
                />
              </span>
              <span v-if="row == 'coverages'">
                <ApprovalCoverageDetail
                  :coverages="approvalOldValue ? approvalOldValue[row] : []"
                />
              </span>

              <span
                v-if="row != 'coverages' && row != 'offices'"
                class="mr-2"
                >{{ approvalOldValue ? approvalOldValue[row] : "---" }}</span
              >
            </td>
            <td
              :class="
                getClassIfUpdated(approvalOldValue, approvalNewValue, row) +
                '--text'
              "
            >
              <span v-if="row == 'offices'">
                <span
                  class="ml-5"
                  v-show="
                    getClassIfUpdated(
                      approvalOldValue,
                      approvalNewValue,
                      row
                    ) === 'red'
                  "
                  >Offices information has changed</span
                >
                <ApprovalOfficeDetail
                  :offices="approvalNewValue[row]"
                  :showUpdate="
                    getClassIfUpdated(approvalOldValue, approvalNewValue, row)
                  "
                />
              </span>
              <span v-if="row == 'coverages'">
                <span
                  class="ml-5 mt-1"
                  v-show="
                    getClassIfUpdated(
                      approvalOldValue,
                      approvalNewValue,
                      row
                    ) === 'red'
                  "
                  >Coverages information has changed</span
                >
                <ApprovalCoverageDetail
                  :coverages="approvalNewValue[row]"
                  :showUpdate="
                    getClassIfUpdated(approvalOldValue, approvalNewValue, row)
                  "
                />
              </span>
              <span v-if="row != 'coverages' && row != 'offices'" class="mr-2">
                {{ approvalNewValue ? approvalNewValue[row] : "---" }}
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import ApprovalOfficeDetail from "../components/ApprovalOfficeDetail.vue";
import ApprovalCoverageDetail from "../components/ApprovalCoverageDetail.vue";
export default {
  components: { ApprovalOfficeDetail, ApprovalCoverageDetail },
  props: ["approvalOldValue", "approvalNewValue"],
  methods: {
    formatFieldNames(text) {
      const result = text.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;
    },
    formatOfficeInfo(office) {
      console.log(office);
      return "Office Info";
    },
    getClassIfUpdated(approvalOldValue, approvalNewValue, row) {
      let oldValue = approvalOldValue ? approvalOldValue[row] : "";
      let newValue = approvalNewValue ? approvalNewValue[row] : "";
      if (row === "offices") {
        oldValue = approvalOldValue
          ? JSON.stringify(approvalOldValue[row])
          : "";
        newValue = approvalNewValue
          ? JSON.stringify(approvalNewValue[row])
          : "";
      }
      return oldValue == newValue ? "black" : "red";
    },
  },
};
</script>
