<template>
  <v-container>
    <div v-if="provider.providerId">
      <div>
        <h1 class="py-0 mb-1 text-h5">
          Profile {{ provider.providerNumber }} - {{ provider.providerName }}
        </h1>
        <h3 v-show="isStatusPending" class="red--text">
          Your profile information is pending approval
        </h3>
        <div v-show="provider.isSuspended">
          <h3 class="red--text">
            Your account has been suspended. You will not be able to submit any
            claims. Please contact administrator.
          </h3>
          <v-alert dense outlined type="error" v-show="provider.adminMessage">
            {{ provider.adminMessage }}
          </v-alert>
        </div>
        <h3
          v-show="
            provider.approvalStatus === 'Rejected' && !provider.isStatusRead
          "
          class="red--text"
        >
          Your requested profile information change has been rejected. Please
          contact administrator for clarification.
          <v-btn
            text
            @click="showRejectDetails"
            class="blue--text text-capitalize"
            >See details</v-btn
          >
        </h3>
      </div>
      <v-card flat class="mx-auto">
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="(e) => saveProfile(e)"
        >
          <v-expansion-panels focusable tile multiple :value="expanded">
            <v-expansion-panel>
              <v-expansion-panel-header>
                Provider Information
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card class="pt-2 pb-5" flat>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        class="mt-2"
                        dense
                        v-model="provider.providerName"
                        :rules="textRules"
                        label="Name"
                        name="providerName"
                        hint="Please Enter Provider's Name"
                        required
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="12" sm="3">
                      <v-text-field
                        class="mt-2"
                        dense
                        v-model="provider.registrationNumber"
                        :rules="regNumberRules"
                        label="Registration Number"
                        name="registrationNumber"
                        hint="Please provide if applicable."
                        required
                      ></v-text-field>
                    </v-col> -->
                    <v-col cols="12" sm="6">
                      <v-text-field
                        class="mt-2"
                        dense
                        v-model="provider.providerNumber"
                        label="Provider Number"
                        :hint="
                          provider.providerNumber
                            ? ''
                            : `Provider number will be generated or prefilled on approval`
                        "
                        persistent-hint
                        name="providerNumber"
                        readonly
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <h2 class="text-h6">Clinic/Practitioner Information</h2>
                  <v-expansion-panels
                    focusable
                    tile
                    multiple
                    :value="expanded1"
                  >
                    <ProfileProviderOffice
                      v-for="(n, i) in officeIds"
                      :key="n"
                      :officeStartId="n"
                      :office="offices[i]"
                    >
                      <template v-slot:default>
                        <v-btn
                          tile
                          color="secondary"
                          class="mt-2"
                          small
                          @click="removeOffice(n)"
                        >
                          REMOVE
                        </v-btn>
                      </template>
                    </ProfileProviderOffice>
                  </v-expansion-panels>
                </v-card>
                <v-btn
                  tile
                  color="secondary"
                  class="ml-4 mb-4"
                  @click="addOffice"
                  >Add Another Clinic / Practitioner</v-btn
                >
                <v-divider></v-divider>

                <!-- Provider Benefits -->
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Areas of Specialization - click to expand
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card class="pa-0" flat>
                      <v-card-title class="text-h6"
                        >Areas of Specialization</v-card-title
                      >
                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                            v-for="(area, index) in areasOfSpecialization"
                            :key="area.coverageId"
                            class="ma-0 pa-0"
                          >
                            <v-checkbox
                              :label="area.name"
                              :value="area"
                              v-model="provider.coverages"
                              color="primary"
                              :hide-details="
                                index !== areasOfSpecialization.length - 1
                              "
                              name="coverages"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-divider class="mb-2"></v-divider>
                <!-- Terms and conditions -->
                <v-checkbox
                  v-model="provider.verifyProviderRegistration"
                  label="Verify Provider Registration"
                  name="verifyProviderRegistration"
                  hide-details
                  :value="true"
                  class="mt-4"
                ></v-checkbox>

                <TermsAndConditions />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-card-actions>
            <v-btn
              tile
              color="secondary"
              class="mt-4 mb-6"
              :disabled="!valid || isStatusPending"
              type="submit"
              :loading="isLoading"
              >SAVE</v-btn
            >
            <small class="ml-4 red--text" v-if="isStatusPending"
              >You will not be able to save until your profile is
              approved</small
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </div>
    <div v-else>
      <p>No provider's information</p>
    </div>

    <!-- Reject Details Dialog -->
    <div class="text-center">
      <v-dialog v-model="showDetailsDilaog" max-width="1200">
        <v-card class="pa-5 pt-4">
          <v-btn
            icon
            @click="showDetailsDilaog = false"
            class="float-right ml-2 mt-n3 mr-n4"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-card-text class="text-h6"
            >Requested change information</v-card-text
          >
          <ApprovalValue
            :approvalOldValue="provider.oldValue"
            :approvalNewValue="provider.newValue"
          />
          <v-card-actions>
            <v-row justify="end">
              <v-btn text @click="hideStatusMessage" class="mt-4" color="blue">
                CLOSE AND HIDE
              </v-btn>
              <v-btn text @click="showDetailsDilaog = false" class="mt-4">
                CLOSE
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
import ProfileProviderOffice from "../components/ProfileProviderOffice.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import CoveragesService from "../services/CoveragesService";
import ProviderService from "../services/ProviderService";
import ApprovalValue from "../components/ApprovalValue.vue";
import ApprovalsService from "../services/ApprovalsService";
import TermsAndConditions from "../components/TermsAndConditions.vue";

export default {
  components: {
    ProfileProviderOffice,
    ApprovalValue,
    TermsAndConditions,
  },
  data() {
    return {
      valid: false,
      expanded: [0, 1],
      expanded1: [0],
      isLoading: false,
      officeId: 0,
      officeIds: [0],
      coverages: [],
      areasOfSpecialization: [],
      showDetailsDilaog: false,
      textRules: [
        (v) => !!v || "Please Enter",
        // (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      regNumberRules: [
        (v) => /\d$/.test(v) || "Registration number must be valid",
      ],
      timezone:
        Intl.DateTimeFormat().resolvedOptions().timeZone +
        " " +
        new Date().toString(),
    };
  },
  watch: {
    officeIds: function () {
      this.validate();
    },
    offices: function () {
      this.officeIds = this.provider.offices.map((o, i) => i);
      this.expanded1 = this.provider.offices.map((o, i) => i);
      this.officeId = this.provider.offices.length;
    },
  },
  computed: {
    ...mapState("provider", ["provider"]),
    ...mapGetters("provider", ["offices"]),
    ...mapGetters("enums", ["provinces"]),
    isStatusPending() {
      return this.provider.approvalStatus === "Pending";
    },
  },
  mounted() {
    // if (this.provider.providerId !== null && !this.provider.providerNumber) {
    //   console.log(!this.provider)
    //   this.$router.push({ name: "ProviderRegistration" });
    // }
    CoveragesService.getCoverages()
      .then((res) => {
        this.areasOfSpecialization = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    this.officeIds = this.provider.offices.map((o, i) => i);
    this.officeId = this.provider.offices.length;
    this.getProvinces();
  },
  methods: {
    ...mapActions("notification", ["show"]),
    ...mapActions("provider", ["setProfile"]),
    ...mapActions("enums", ["getProvinces"]),
    validate() {
      this.$refs.form.validate();
    },
    addOffice() {
      this.officeId++;
      this.officeIds.push(this.officeId);
      this.expanded1.push(this.officeId - 1);
    },
    removeOffice(id) {
      const index = this.officeIds.indexOf(id);
      this.officeIds.splice(index, 1);
    },
    showRejectDetails() {
      this.showDetailsDilaog = true;
    },
    async hideStatusMessage() {
      const res = await ApprovalsService.getApproval(
        this.provider.latestAuditId
      );
      if (!res.data) return;
      const audit = res.data;
      audit.isStatusRead = true;
      ApprovalsService.updateApproval(audit.id, audit)
        .then(() => {
          this.$store.dispatch("provider/getProfile", this.provider.providerId);
          this.showDetailsDilaog = false;
        })
        .catch((e) => console.log(e));
    },
    saveProfile(e) {
      this.isLoading = true;
      const formData = new FormData(e.target);
      const payload = {
        providerId: this.provider.providerId,
        offices: [],
      };
      let isOffice = false;
      let currentOffice = null;
      for (var pair of formData.entries()) {
        const [key, value] = pair;
        if (key === "officeStartId" && value !== currentOffice) {
          isOffice = true;
          currentOffice = value;
          payload.offices.push({
            verifyDirectDeposit: false,
          });
          continue;
        }
        if (key == "officeEnd") {
          isOffice = false;
          continue;
        }
        if (isOffice) {
          const lastIndex = payload.offices.length - 1;
          // console.log("key: ", key, "value: ", value);
          if (key == "officeId" && value == "") continue;
          if (key == "provinceId") {
            payload.offices[lastIndex]["province"] = this.provinces.find(
              (p) => p.id === value
            )?.code;
          }
          if (key == "coverages") continue;
          if (key == "verifyDirectDeposit") {
            payload.offices[lastIndex][key] = value == "true" ? true : false;
            continue;
          }
          payload.offices[lastIndex][key] = value;
          payload.offices[lastIndex]["BusinessID"] =
            this.offices[lastIndex]?.businessID || "";
          payload.offices[lastIndex]["BusinessName"] =
            this.offices[lastIndex]?.businessName || "";
        } else {
          payload[key] = value;
        }
      }

      payload.offices.forEach((o) => (o.accountType = "Chequing"));
      payload.coverages = this.provider.coverages;
      payload.verifyProviderRegistration =
        this.provider.verifyProviderRegistration ?? false;
      const updatedProvider = { ...this.provider, ...payload };

      ProviderService.updateProfile(updatedProvider)
        .then(() => {
          this.isLoading = false;
          this.$store.dispatch(
            "provider/getProfile",
            updatedProvider.providerId
          );
          window.scrollTo({ top: 0, behavior: "smooth" });
          const notification = {
            type: "success",
            message: "Profile change sent for approval",
          };
          this.show(notification);
        })
        .catch((err) => {
          const notification = {
            type: "error",
            message: err.message,
          };
          this.show(notification);
          this.isLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.v-input--selection-controls {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
