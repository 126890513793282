<template>
  <v-card flat outlined>
    <v-card-text class="pa-1">
      <v-list-item>
        <v-list-item-content class="py-1">
          <ApprovalValue
            :approvalOldValue="approval.oldValue"
            :approvalNewValue="approval.newValue"
          />
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
  </v-card>
</template>

<script>
import ApprovalValue from "./ApprovalValue.vue";
export default {
  components: { ApprovalValue },
  props: ["approval"],
};
</script>

<style>
</style>