<template>
  <v-container>
    <v-card flat>
      <h1 class="mx-auto mb-3 text-h5 text-sm-h4">Welcome to the Admin Page</h1>
      <p>
        Provider's Admin can view / edit plans and coverages, view / approve
        requests to change providers info.<br /><br />
        Admininstrator can login as provider (impersonate) to act as provider
        and resovle any issues the provider might have. Use Login as Provider
        button available in provider details dialog.
      </p>
    </v-card>
  </v-container>
</template>
<script>
export default {};
</script>
<style scoped>
p {
  text-align: justify;
}
</style>