import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store';
import Login from '../views/Login.vue';
import Profile from '../views/Profile.vue';
import NewClaim from '../views/NewClaim.vue';
import SearchSubmissions from '../views/SearchSubmissions.vue';
import AdminSearchSubmissions from '../views/AdminSearchSubmissions.vue';
import ProviderRegistration from '../views/ProviderRegistration.vue';
import NotFound from '../views/NotFound.vue';
import Faq from '../views/Faq.vue';
import Privacy from '../views/Privacy.vue';
import Accessibility from '../views/Accessibility.vue';
import AdminHome from '../views/AdminHome.vue';
import ClaimsAdminHome from '../views/ClaimsAdminHome.vue';
import AdminProviders from '../views/AdminProviders.vue';
import EditPlans from '../views/EditPlans.vue';
import EditCoverages from '../views/EditCoverages.vue';
import ApprovalRequests from '../views/ApprovalRequests.vue';
import AdminProvidersHomepage from '../views/AdminProvidersHomepage.vue';
import AuthService from '../services/AuthService';
import NewProvider from '../views/NewProvider.vue';
import MaintenancePage from '../views/MaintenancePage.vue';

const routes = [
  {
    path: '/',
    name: 'login',
    meta: { layout: 'not-logged-in' },
    component: Login,
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    meta: { layout: 'none' },
    component: MaintenancePage,
  },
  {
    path: '/newprovider/:uid',
    name: 'NewProvider',
    component: NewProvider,
    meta: { requiresAuth: false, layout: 'no-sidebar-not-logged-in' },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Home,
    meta: { requiresAuth: true, layout: 'default' },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: { requiresAuth: true, layout: 'default' },
  },
  {
    path: '/new-claim',
    name: 'NewClaim',
    component: NewClaim,
    meta: { requiresAuth: true, layout: 'default' },
  },
  {
    path: '/search-submissions',
    name: 'SearchSubmissions',
    component: SearchSubmissions,
    meta: { requiresAuth: true, layout: 'default' },
  },
  {
    path: '/admin-search-submissions',
    name: 'AdminSearchSubmissions',
    component: AdminSearchSubmissions,
    meta: { requiresAuth: true, layout: 'admin', requiresAdmin: true },
  },
  {
    path: '/claims-admin-search-submissions',
    name: 'ClaimsAdminSearchSubmissions',
    component: AdminSearchSubmissions,
    meta: { requiresAuth: true, layout: 'claimsAdmin', requiresAdmin: true },
  },
  {
    path: '/faq',
    name: 'faq',
    component: Faq,
    meta: { requiresAuth: true, layout: 'default' },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy,
    meta: { requiresAuth: true, layout: 'default' },
  },
  {
    path: '/accessibility',
    name: 'accessibility',
    component: Accessibility,
    meta: { requiresAuth: true, layout: 'default' },
  },
  {
    path: '/provider-registration',
    meta: { layout: 'no-sidebar', requiresAuth: false },
    name: 'ProviderRegistration',
    component: ProviderRegistration,
  },
  {
    path: '/admin-home',
    meta: { layout: 'admin', requiresAuth: true, requiresAdmin: true },
    name: 'AdminHome',
    component: AdminHome,
  },
  {
    path: '/claims-admin-home',
    meta: { layout: 'claimsAdmin', requiresAuth: true, requiresAdmin: true },
    name: 'ClaimsAdminHome',
    component: ClaimsAdminHome,
  },
  {
    path: '/admin-providers',
    meta: { layout: 'admin', requiresAuth: true, requiresAdmin: true },
    name: 'AdminProviders',
    component: AdminProviders,
  },
  {
    path: '/claims-admin-providers',
    meta: { layout: 'claimsAdmin', requiresAuth: true, requiresAdmin: true },
    name: 'ClaimsAdminProviders',
    component: AdminProviders,
  },
  {
    path: '/admin-plans',
    meta: { layout: 'admin', requiresAuth: true, requiresAdmin: true },
    name: 'EditPlans',
    component: EditPlans,
  },
  {
    path: '/admin-coverages',
    meta: { layout: 'admin', requiresAuth: true, requiresAdmin: true },
    name: 'EditCoverages',
    component: EditCoverages,
  },
  {
    path: '/admin-approvals',
    meta: { layout: 'admin', requiresAuth: true, requiresAdmin: true },
    name: 'ApprovalRequests',
    component: ApprovalRequests,
  },
  {
    path: '/admin-edit-homepage',
    meta: { layout: 'admin', requiresAuth: true, requiresAdmin: true },
    name: 'EditHomepage',
    component: AdminProvidersHomepage,
  },
  {
    path: '*',
    meta: { layout: 'no-sidebar' },
    name: '404',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
let isMaintenance = process.env.VUE_APP_MAINTENANCE_MODE;

router.beforeEach(async (to, from, next) => {
  const user = await AuthService.getUser();
  const query = window.location.search;

  if (user && user.profile.role == 'Administrator') isMaintenance = 'false';

  if (
    isMaintenance === 'true' &&
    to.path !== '/maintenance' &&
    query !== '?admin'
  ) {
    window.location.href = '/maintenance';
    return;
  }

  if (to.matched.some((record) => !record.meta.requiresAuth)) {
    next();
    return;
  }

  if (!user) {
    return AuthService.login();
  }

  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (
      user.profile.role !== 'Administrator' &&
      user.profile.role !== 'ClaimsAdmin'
    ) {
      window.location.href = '/dashboard';
      return;
    }
  }

  store.dispatch('auth/setUser', user);
  next();
});

Vue.use(VueRouter);

export default router;
