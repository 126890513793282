<template>
  <v-row justify="center">
    <v-dialog
      :value="dialog"
      @input="$emit('close')"
      max-width="1450px"
      scrollable
    >
      <v-card class="pa-2 flex-grow-1">
        <v-card-text class="pt-0">
          <v-row>
            <v-col>Plan Details</v-col>
            <v-btn icon @click="close" class="mt-4">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12" sm="6" md="2" class="pt-0">
              <v-text-field
                v-model="plan.planNumber"
                label="Plan Number"
                required
                :rules="[(v) => !!v || 'Please enter number']"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="2" class="pt-0">
              <v-text-field
                v-model="plan.planName"
                label="Plan Name"
                :rules="[(v) => !!v || 'Please enter name']"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="2" class="pt-0">
              <v-menu
                v-model="showDatepicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="effectiveDate"
                    label="Effective Date"
                    name="effectiveDate"
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    :rules="[(v) => !!v || 'Please enter date']"
                    readonly
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="effectiveDate"
                  @input="showDatepicker = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-spacer></v-spacer>
            <v-col
              cols="12"
              sm="6"
              md="3"
              class="pt-0"
              v-if="plan.isDrawbridgePlan"
            >
              <v-text-field
                class="guid"
                v-model="plan.planIdentityId"
                label="Plan Id"
                :rules="guidRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="2" class="pt-4" align-self="center">
              <v-checkbox
                v-model="plan.isDrawbridgePlan"
                label="Drawbrige Plan"
                required
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="6" md="1" align-self="center" class="pt-0">
              <v-chip color="primary" class="text-h6 mr-0 ml-auto">
                {{ plan.planNumber }}
              </v-chip>
            </v-col>
          </v-row>
          <small
            >Enter Plan number and name. Select at least one coverage to include
            in plan. Select if referral required for the coverage claim. Assign
            custom ID per plan if required by clicking on coverage
            number.</small
          >
          <v-row class="text-subtitle-1 font-weight-bold mt-1">
            <span> Available Coverages </span>
            <v-spacer></v-spacer>
            <v-avatar color="grey lighten-2" size="20" class="mx-1"></v-avatar>
            <span class="text-subtitle-2 mr-2">
              - originaly assignedId Id
            </span>
            <v-avatar color="primary" size="20" class="mx-1"></v-avatar>
            <span class="text-subtitle-2"> - custom Id in this plan </span>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="2"
              sm="4"
              v-for="coverage in areasOfSpecialization"
              :key="coverage.coverageId"
              class="ma-0 pa-0"
            >
              <v-card class="ma-1" hover>
                <div>
                  <div>
                    <div class="float-right">
                      <v-avatar
                        v-if="coverage.customId"
                        color="grey lighten-2"
                        size="24"
                        >{{ coverage.assignedId }}</v-avatar
                      >
                      <!-- Custom Id dialog -->
                      <v-menu
                        :close-on-content-click="false"
                        :nudge-width="150"
                        offset-x
                      >
                        <template v-slot:activator="{ on: menu, attrs }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip }">
                              <v-btn
                                fab
                                depressed
                                :color="
                                  coverage.customId
                                    ? 'primary'
                                    : 'grey lighten-2'
                                "
                                x-small
                                class="text-subtitle-2 ma-1"
                                v-bind="attrs"
                                v-on="{ ...tooltip, ...menu }"
                              >
                                {{
                                  coverage.customId
                                    ? coverage.customId
                                    : coverage.assignedId
                                }}
                              </v-btn>
                            </template>
                            <span>Assign custom ID</span>
                          </v-tooltip>
                        </template>
                        <v-list>
                          <v-list-item>
                            <v-text-field
                              type="number"
                              v-model="coverage.customId"
                              :value="coverage.customId"
                              :rules="customIdRules"
                              clearable
                              label="Custom coverage ID per Plan"
                            ></v-text-field>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <!-- End custom ID dialog -->
                    </div>
                    <v-checkbox
                      :label="coverage.name"
                      :value="coverage.coverageId"
                      v-model="coveragesSelected"
                      color="primary"
                      class="black--text"
                      hide-details
                      name="coverages"
                    >
                    </v-checkbox>
                  </div>
                </div>
                <v-checkbox
                  v-model="referralsRequired"
                  :value="coverage.coverageId"
                  color="gray"
                  dense
                  hide-details
                  name="referral"
                >
                  <template v-slot:label>
                    <small>referral required</small>
                  </template>
                </v-checkbox>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click.native="close">
            Close
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click.native="confirmDelete"
            v-show="!isNew"
          >
            Delete
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click.native="savePlan"
            :disabled="saveDisabled"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Confirm Delete Dialog -->
    <v-dialog v-model="confirmDialog" max-width="500px">
      <v-card>
        <v-card-title> Are you sure? </v-card-title>
        <v-card-text>
          The Plan
          <span class="primary--text font-weight-bold"
            >{{ plan.planName }}{{ plan.planName ? " - " : "" }}
            {{ plan.planNumber }}</span
          >
          will be deleted!
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="confirmDialog = false">
            Cancel
          </v-btn>
          <v-btn color="error" text @click="deletePlan"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
    
<script>
import CoveragesService from "../services/CoveragesService";
const guidPattern =
  /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/;
export default {
  props: ["dialog", "plan", "isNew"],
  data() {
    return {
      showDatepicker: false,
      areasOfSpecialization: [],
      confirmDialog: false,
      openIdMenu: false,
      customID: "",
      referralsRequired: [],
      coveragesSelected: [],
      coveragesWithCustomId: [],
      customIdRules: [(v) => this.checkIfIdIsUsed(v) || "ID is used"],
      guidRules: [
        (v) => !!v || "Please enter Plan Id",
        (v) => guidPattern.test(v) || "Please enter valid Guid",
      ],
    };
  },
  computed: {
    guidValid() {
      return guidPattern.test(this.plan.planIdentityId);
    },
    saveDisabled() {
      const result =
        this.coveragesSelected.length === 0 ||
        this.plan.planNumber === "" ||
        this.plan.planName === "" ||
        !this.plan.effectiveDate ||
        !this.guidValid;
      return result;
    },
    effectiveDate: {
      get: function () {
        const date =
          this.plan.effectiveDate &&
          this.plan.effectiveDate.toString().slice(0, 10);
        return date;
      },
      set: function (value) {
        this.plan.effectiveDate = value;
      },
    },
  },

  methods: {
    checkIfIdIsUsed(id) {
      return (
        this.areasOfSpecialization.filter((a) => a.assignedId === parseInt(id))
          .length === 0
      );
    },
    reset() {
      this.referralsRequired = [];
      this.coveragesSelected = [];
    },
    close() {
      this.$emit("close", false);
      this.reset();
    },
    confirmDelete() {
      this.confirmDialog = true;
    },
    deletePlan() {
      this.confirmDialog = false;
      this.$emit("delete", this.plan);
      this.reset();
    },
    resetCoverageId(coverage) {
      const index = this.plan.coverages.findIndex(
        (c) => c.coverageId === coverage.coverageId
      );
      this.plan.coverages[index].customId = null;
      const areaIndex = this.areasOfSpecialization.findIndex(
        (c) => c.coverageId === coverage.coverageId
      );
      this.areasOfSpecialization[areaIndex].customId = null;
    },
    savePlan() {
      this.confirmDialog = false;
      this.plan.coverages = this.coveragesSelected.map((coverage) => {
        const selectedCoverage = this.areasOfSpecialization.filter(
          (c) => c.coverageId === coverage
        )[0];
        selectedCoverage.requiresReferral = this.referralsRequired.includes(
          selectedCoverage.coverageId
        );
        return selectedCoverage;
      });
      this.$emit("save", this.plan);
      this.reset();
    },
  },
  watch: {
    dialog: function () {
      if (this.dialog) {
        CoveragesService.getCoverages()
          .then((res) => {
            this.areasOfSpecialization = res.data;
            this.coveragesSelected = this.plan.coverages.map(
              (coverage) => coverage.coverageId
            );
            const trueRefferals = this.plan.coverages.filter(
              (coverage) => coverage.requiresReferral
            );
            this.coveragesWithCustomId = this.plan.coverages.filter(
              (coverage) => coverage.customId
            );

            this.coveragesWithCustomId.forEach((c) => {
              const index = this.areasOfSpecialization.findIndex(
                (area) => area.coverageId === c.coverageId
              );
              this.areasOfSpecialization[index].customId = c.customId;
            });
            this.referralsRequired = trueRefferals.map(
              (coverage) => coverage.coverageId
            );
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.reset();
      }
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin: 0 !important;
  padding: 0 !important;
}

.black--text /deep/ label {
  color: black;
}

.primary--text /deep/ label {
  color: #007cb5;
}
.guid /deep/ .v-text-field__slot {
  font-size: 12px;
}
</style>