var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pt-0"},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.searchData,"options":_vm.tableOptions,"server-items-length":_vm.totalCount,"footer-props":{ 'items-per-page-options': [10, 15, 30, 50, 100, -1] },"show-expand":"","expanded":_vm.expanded,"item-key":"claimId","item-class":_vm.getStatusClass},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.planNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.planNumber === "777" ? "247" : item.planNumber)+" ")]}},{key:"item.submissionDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.submissionDate))+" ")]}},{key:"item.providerId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getProviderName(item.providerId))+" ")]}},{key:"item.claimSubmissionLog",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":_vm.headers.length}},[_c('ClaimStatusSelect',{attrs:{"item":item}})],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":headers.length}},[_c('ClaimDetail',{attrs:{"claim":item}})],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }