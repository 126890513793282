<template>
  <v-card class="overflow-hidden">
    <v-app>
      <v-img :src="require('@/assets/pbas_group_logo.svg')" contain max-height="110" class="ma-5 mr-auto ml-10" />
      <v-divider></v-divider>
      <v-main>
        <v-container fluid>
          <slot />
        </v-container>
      </v-main>
    </v-app>
  </v-card>
</template>

<script>
export default {};
</script>
