<template>
  <v-card class="overflow-hidden">
    <v-app>
      <Appbar :links="links" />
      <SideBar :links="links" />
      <v-main>
        <v-container fluid class="pa-sm-5 pa-0">
          <slot />
        </v-container>
      </v-main>
      <SnackBar />
      <Footer />
    </v-app>
  </v-card>
</template>

<script>
import Appbar from "../components/Appbar.vue";
import SideBar from "../components/SideBar.vue";
import Footer from "../components/Footer.vue";
import SnackBar from "../components/SnackBar.vue";
export default {
  components: { SideBar, Footer, Appbar, SnackBar },
  data() {
    return {
      drawer: false,
      links: [
        {
          src: require("@/assets/icon_admin_home.png"),
          text: "Admin Home",
          route: "/admin-home",
        },
        {
          src: require("@/assets/icon_provider_list.png"),
          text: "View Providers",
          route: "/admin-providers",
        },
        {
          src: require("@/assets/icon_plans.png"),
          text: "Edit Plans",
          route: "/admin-plans",
        },
        {
          src: require("@/assets/icon_coverages.png"),
          text: "Edit Coverages",
          route: "/admin-coverages",
        },
        {
          src: require("@/assets/icon_approvals.png"),
          text: "Approval Requests",
          route: "/admin-approvals",
        },
        {
          src: require("@/assets/icon_search_submissions.png"),
          text: "Search Submissions",
          route: "/admin-search-submissions",
        },
        {
          src: require("@/assets/icon_edit_page.png"),
          text: "Provider's Homepage",
          route: "/admin-edit-homepage",
        },
      ],
    };
  },
};
</script>
<style scoped>
.contact-us {
  position: fixed !important;
  right: 0;
  top: 450px;
  transform-origin: bottom right;
  transform: rotate(270deg);
  z-index: 1000;
}
</style>
