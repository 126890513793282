<template>
  <v-card class="overflow-hidden">
    <v-app-bar
      app
      clippedLeft
      clippedRight
      fixed
      extension-height="10"
      height="65"
      :src="require('../assets/default_banner.jpg')"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="90deg, rgba(255,255,255,0.9) 20%, rgba(255,255,255,0) 70%"
        ></v-img>
      </template>
      <div>
        <a href="https://pbas.ca/" target="_blank">
          <v-img
            :src="require('@/assets/pbas_group_logo.svg')"
            content-class="ma-n8 ma-sm-0"
          />
        </a>
      </div>
      <v-spacer></v-spacer>

      <v-btn
        text
        small
        dark
        plain
        route
        to="/profile"
        class="text-subtitle-2 text-capitalize pr-2"
      >
        <v-icon left dark size="22"> mdi-account-star-outline </v-icon>
        <span class="d-none d-sm-flex text-uppercase"
          >{{ loading ? "logging out..." : username }}
        </span>
      </v-btn>
      <v-btn icon dark plain @click="logout" :loading="loading">
        <v-icon dark> mdi-exit-to-app </v-icon>
      </v-btn>
      <template v-slot:extension>
        <v-tabs
          align-with-title
          class="d-flex d-sm-none green pl-0"
          height="20"
          right
          dark
          show-arrows
        >
          <v-tab
            class="text-subtitle-2 text-capitalize"
            v-for="link in links"
            :key="link.text"
            route
            :to="link.route"
            >{{ link.text }}</v-tab
          >
        </v-tabs>
      </template>
    </v-app-bar>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AuthService from "../services/AuthService";
export default {
  props: ["links"],
  data() {
    return {
      loading: false,
    };
  },
  components: {},
  computed: {
    ...mapGetters("auth", ["user"]),
    username() {
      if (this.$store.getters["auth/user"]) {
        return this.$store.getters["auth/user"].profile.username;
      }
      return "";
    },
  },
  methods: {
    ...mapActions({ authLogout: "auth/logout" }),
    async logout() {
      this.loading = true;
      await AuthService.logout();
      localStorage.removeItem("user");
      this.$store.dispatch("auth/logout");
    },
  },
};
</script>
<style scoped></style>
